import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GeneralInfoComponent} from "./general-info.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatStepperModule} from "@angular/material/stepper";
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [GeneralInfoComponent],
  exports: [GeneralInfoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    TranslocoModule,
  ],
})
export class GeneralInfoModule {}
