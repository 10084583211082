import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
    selector: '[stopPropagation]'
})
export class StopPropagationDirective implements AfterViewInit, OnDestroy {
    private _listeners: (() => void)[] = [];
    @Input('stopPropagation') public events!: string | string[];

    constructor(
        private elRef: ElementRef<HTMLElement>,
        private renderer: Renderer2
    ) { }

    ngAfterViewInit(): void {
        this.events ||= ['click'];
        const events = Array.isArray(this.events) ? this.events : [this.events];
        this._listeners = events.map(eventName => this.renderer.listen(
                this.elRef.nativeElement,
                eventName,
                (event: Event) => {
                    event.stopPropagation();
                }
            ));
    }

    ngOnDestroy(): void {
        this._listeners.forEach(listener => listener());
    }

}
