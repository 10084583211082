import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {LangDefinition, TranslocoService} from '@ngneat/transloco';
import {share} from 'rxjs/operators';

@Component({
    selector: 'widget-language-selector',
    templateUrl: './language-selector.component.html',
    exportAs: 'languages',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
    public availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];
    public activeLang$ = this._translocoService.langChanges$.pipe(share());
    public flagCodes = {
        'en': 'us',
        'de': 'de'
    };

    constructor(
        private _translocoService: TranslocoService
    ) {
    }

    setActiveLang(lang: string): void {
        this._translocoService.setActiveLang(lang);
    }
}

@NgModule({
    declarations: [LanguageSelectorComponent],
    exports: [LanguageSelectorComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule
    ]
})
export class LanguageSelectorComponentModule { }


// FIXME: to be refactored to use input and output
