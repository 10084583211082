import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef, EventEmitter,
  Input,
  OnInit, Output,
  TemplateRef,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import {
  PaginationQuery,
  PaginationResult,
} from '@tante-tobi-web/infrastructure';
import { AppUtils, assertNotNullOrUndefined } from '@tante-tobi-web/utils';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { TableSidenavContentDirective } from './table-sidenav-content.directive';

type Data = Record<string, any>[];

@Component({
  selector: 'partial-table',
  templateUrl: './table.component.html',
  host: {
    class: 'shadow block rounded overflow-auto',
  },
  styles: [
    `
      :host {
        ::ng-deep {
          .mat-drawer-backdrop {
            @apply fixed z-9999 #{!important};
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit {
  // dataSource needs to be ReplaySubject, BehaviorSubject won't work because it will show
  // the empty data state at first if supplied with empty array as initial value
  public dataSource = new ReplaySubject<Data>();
  @Input() public displayColumns: Column[] = [];
  @Input() public fetchProvider!: FetchProvider<Data>;
  @Output() public closed: EventEmitter<void> = new EventEmitter<void>()

  @ContentChild('sidenavContent', { static: true, read: TemplateRef })
  public sidenavContent?: TemplateRef<any>;
  @ViewChild(TableSidenavContentDirective)
  public tableSidenavContentDirective?: TableSidenavContentDirective;
  @ViewChild(MatPaginator, { static: true }) private paginator!: MatPaginator;
  @ViewChild(MatPaginator, { static: true, read: HTMLElement })
  private paginatorElement!: HTMLElement;

  get displayedColumns() {
    return this.displayColumns.map((it) => it.key);
  }

  constructor(
    private _cdf: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private translocoService: TranslocoService
  ) {}

  get element() {
    return this._elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = this.translocoService.translate('ITEMS_PER_PAGE');
    assertNotNullOrUndefined(
      this.fetchProvider,
      'TableComponent.fetchProvider is either null or undefined'
    );
    this.fetchData(new PaginationQuery(0, this.paginator.pageSize)).subscribe(
      (response) => {
        this.dataSource.next(response.data);
      }
    );
  }

  fetchData(query: PaginationQuery) {
    if (
      AppUtils.isNullOrUndefined(this.fetchProvider) ||
      typeof this.fetchProvider !== 'function'
    ) {
      throw new Error('fetchProvider must be observable with array of event');
    }
    return this.fetchProvider(query).pipe(
      tap((response) => {
        this.dataSource.next(response.data);
        this.paginator.length = response.allRecords;
        this._cdf.markForCheck();
      })
    );
  }

  onPaginate(event: PageEvent) {
    this.fetchData(
      new PaginationQuery(event.pageIndex, event.pageSize)
    ).subscribe();
  }

  toggleSidenav(row: Record<string, any>) {
    if (this.tableSidenavContentDirective) {
      this.tableSidenavContentDirective.toggle(row);
      this._cdf.markForCheck();
    }
  }

  closeSidenav() {
    if (this.tableSidenavContentDirective) {
      this.tableSidenavContentDirective.close();
    }
  }

  refresh() {
    this.fetchData(new PaginationQuery(0, this.paginator.pageSize)).subscribe(
      (response) => {
        this.dataSource.next(response.data);
      }
    );
  }
}

interface IColumnOptions {
  key: string;
  title: string;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  component?: Type<any>;
  class?: string[] | string;
  translate?: boolean;
  valueFormatter?: (row: Record<string, any>, cell: any) => string;
}

export class Column {
  type!: 'component' | 'valueFormatter' | 'default';
  key: string;
  title: string;
  minWidth: string;
  maxWidth?: string;
  width: string;
  class?: string[] | string;
  component?: Type<any>;
  translate?: boolean;
  valueFormatter?: (row: Record<string, any>, cell: any) => string;
  constructor(options: IColumnOptions) {
    this.key = options.key;
    this.title = options.title;
    this.width = options.width ?? '175px';
    this.minWidth = options.minWidth ?? '175px';
    this.maxWidth = options.maxWidth;
    this.component = options.component;
    this.class = options.class;
    this.translate = options.translate || false
    this.valueFormatter = options.valueFormatter;
    this.#setType();
  }

  #setType() {
    if (this.component) {
      this.type = 'component';
    } else if (this.valueFormatter) {
      this.type = 'valueFormatter';
    } else {
      this.type = 'default';
    }
  }
}

export type FetchProvider<T> = (
  query: PaginationQuery
) => Observable<PaginationResult<T>>;
