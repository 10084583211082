import { TranslocoModule } from '@ngneat/transloco';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialModule} from '@tante-tobi-web/shared';
import {SidenavDetailsHeaderComponent} from './sidenav-details-header.component';


@NgModule({
  declarations: [
    SidenavDetailsHeaderComponent
  ],
  exports: [
    SidenavDetailsHeaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule
  ]
})
export class DetailsSidenavWrapperModule { }
