<h5 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h5>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p *ngIf="data.description">{{ data.description }}</p>
  <mat-form-field cdkFocusInitial appearance="fill">
    <input
      (keyup.enter)="close()"
      [(ngModel)]="data.value"
      required
      type="text"
      matInput
    />
  </mat-form-field>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="justify-end">
  <button
    color="accent"
    type="button"
    class="w-6"
    mat-stroked-button
    (click)="close()"
  >
    {{ data.confirm || "button_confirm" }}
  </button>
</div>
