import { TranslocoModule } from '@ngneat/transloco';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StatusCellComponent} from "./status-cell.component";


@NgModule({
  declarations: [
    StatusCellComponent
  ],
  exports: [
    StatusCellComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class StatusCellModule { }
