import {Injectable} from "@angular/core";
import {AbstractUserService, ApplicationUser, TokenManager} from "@tante-tobi-web/infrastructure";
import {Observable, of, tap} from "rxjs";
import {GatewayService} from "../../gateway.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractUserService {
  constructor(
    private gatewayService: GatewayService,
    private tokenManager: TokenManager,
    private _httpClient: HttpClient
  ) {
    super();
  }

  get(): Observable<ApplicationUser> {
    let payLoad = null;
    try {
      const token = localStorage.getItem('accessToken') || '';
      if (!token) {
        return of(null) as any;
      }
      payLoad = JSON.parse(atob(token.split('.')[1]));
      this._user.next(payLoad);

    } catch (err) {
      return of(payLoad);
    }
    return of(payLoad);
  }

  override getUser() {
    return this._httpClient.get<ApplicationUser>('admin/profile/view-profile').pipe(
      tap((user) => this.user = user))
  }

  updateUserProfile(data: ApplicationUser) {
    return this._httpClient.configure({snackbar: {successMessage: 'SUCCESS'}}).put(`admin/profile/update-profile`, data);
  }

  update(user: ApplicationUser): Observable<any> {
    return this.gatewayService.patch('api/common/user', {user})
  }
}
