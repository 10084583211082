import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {RouterModule} from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import {DirectivesModule} from "@tante-tobi-web/shared";
import {LanguageSelectorComponentModule} from "@tante-tobi-web/widgets";
import {ClassyLayoutComponent} from "./layout.component";
import {LayoutNavigationModule} from "./navigation";
import {UserComponent} from "./user";
import {NotificationsMenuModule} from "../notifications-menu";

@NgModule({
  declarations: [ClassyLayoutComponent, UserComponent],
  exports: [ClassyLayoutComponent],
  imports: [
    CommonModule,
    LayoutNavigationModule,
    LanguageSelectorComponentModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    RouterModule,
    DirectivesModule,
    TranslocoModule,
    NotificationsMenuModule,
  ],
})
export class LayoutModule {}
