export interface InstructorUser {
  id: number;
  name: string;
  email: string;
  imageUrl?: string;
  imageId?: string;
  status?: string;
  firstName?: string;
  lastName?: string;
  creationDate: string;
  userId: string;
  isEnabled: string;
  username: string;
  phone: string;
  phoneNumber?: string;
  isEmailVerified: boolean;
  workplace: string;
  taxNumber: string;
  bankName: string;
  branchName: string;
  swiftCode: string;
  addressLine: string;
  bio: string;
  profession: string;
  skills: string[];
  languages: string[];
  yearsOfExperience: number;
  xingUrl: string;
  linkedinUrl: string;
  isApproved: boolean;
  isRejected: boolean;
  roleId: number;
  roleName: string;
  rating: number;
  bankAccountNumber: string;
}

export interface AdminUser {
  id: number;
  firstName: string;
  lastName: string;
  roleId: string;
  email: string;
  creationDate?: string;
  isEnabled: boolean;
  phone: string;
  addressLine: string;
  imageUrl: string;
  imageId: number;
  userName?: string;
  isEmailVerified?: boolean;
  userId: string;
  roleName: string;
}

export interface ClientUser {
  id: number;
  fullName: string;
  email: string;
  organizationName?: string
}

export type ApplicationUser = AdminUser | InstructorUser
