import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FuseMediaWatcherService} from '@tante-tobi-web/fuse';
import {BehaviorSubject, filter, Observable, startWith, Subject, switchMap, takeUntil} from 'rxjs';
import {FuseNavigationItem, FuseNavigationService, LayoutVerticalNavigationComponent} from './navigation';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {NotificationModel} from "@tante-tobi-web/infrastructure";
import {NotificationService} from "@tante-tobi-web/infrastructure";

@Component({
  selector: 'partial-layout',
  templateUrl: './layout.component.html',
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall?: boolean;
  header?: string
  @Input() navigation: FuseNavigationItem[] = [];
  @Input() user: any;
  @Input() applicationName?: string;
  @Input() app?: 'ADMIN' | 'INSTRUCTOR';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  triggerRefresh: BehaviorSubject<number> = new BehaviorSubject<number>(20);
  notifications$ = this.getNotifications()


  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _activatedRoute: ActivatedRoute,
    private _route: Router,
    private _notificationService: NotificationService
  ) {}

  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    this.getRouteHeader()
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({matchingAliases}) => {
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleNavigation(name: string): void {
    const navigation = this._fuseNavigationService.getComponent<LayoutVerticalNavigationComponent>(name);
    if (navigation) {
      navigation.toggle();
    }
  }

  getRouteHeader() {
    this._route.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(new NavigationEnd(1, '', '')),
      takeUntil(this._unsubscribeAll)).subscribe(() => {
      let currentActivateRoute = this._activatedRoute;
      while (!currentActivateRoute.snapshot.data.header && currentActivateRoute.firstChild) {
        currentActivateRoute = currentActivateRoute.firstChild;
      }
      this.header = currentActivateRoute.snapshot.data.header;
    })
  }

  getNotifications(): Observable<NotificationModel.Notifications> {
    return this.triggerRefresh.pipe(switchMap(size => this._notificationService.getNotifications(size)));
  }
}
