import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LumberjackLevel, LumberjackService} from "@ngworker/lumberjack";
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
@Injectable()
export class LoggerInterceptor implements HttpInterceptor {

  constructor(
    private _lumberjackService: LumberjackService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    return next.handle(request)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            const elapsed = Date.now() - started;
            this._lumberjackService.log({
              message: `${request.method} Request for ${request.urlWithParams} took ${elapsed} ms. and the body is`,
              payload: request.body,
              createdAt: Date.now(),
              level: LumberjackLevel.Info
            });
          }
        })
      );
  }
}
