import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BookingModel } from '@tante-tobi-web/infrastructure';

@Component({
  selector: 'partials-onsite-address',
  templateUrl: './onsite-address.component.html',
})
export class OnsiteAddressComponent implements OnInit {
  @Input('row') bookingDetails!: BookingModel.Booking;
  addressForm: FormGroup;
  
  constructor(private _fb: FormBuilder) {
    this.addressForm = this.buildAddressDetailsForm();
  }

  ngOnInit(): void {
    this.addressForm.patchValue(this.bookingDetails);
    this.addressForm.disable();    
  }

  buildAddressDetailsForm() {
    return this._fb.group({
      address: [''],
    });
  }
}
