<div class="flex items-center justify-between my-8">
  <h1 class="text-xl font-bold">{{ title }} <ng-content select="[header]"></ng-content> </h1>
  <ng-container *transloco="let t">
  <button
    mat-icon-button
    type="button"
    matTooltip="{{ t('CLOSE') }}"
    matTooltipShowDelay="500"
    (click)="close()"
  >
    <mat-icon class="icon-size-8">close</mat-icon>
  </button>
  </ng-container>
</div>

<mat-divider></mat-divider>

<div *ngIf="subtitle || showEdit" class="flex items-center">
  <h2 class="text-base font-semibold my-6 text-teal-600">{{ subtitle }}</h2>
  <button mat-icon-button class="ml-4" (click)="edit()" *ngIf="showEdit">
    <mat-icon class="icon-size-4">{{ editMode ? "lock" : "edit" }}</mat-icon>
    <!-- Change to lock -->
  </button>
</div>
