import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SafePipe} from './safe.pipe';
import {WrapPipe} from './wrap/wrap.pipe';

const PIPES = [
  SafePipe,
  WrapPipe
];

@NgModule({
  declarations: PIPES,
  imports: [CommonModule],
  exports: PIPES,
})
export class PipesModule {
}
