<ng-container *transloco="let t">
  <div *ngIf="notifications" class="block">
    <button mat-icon-button [matMenuTriggerFor]="userNotifications"
            [stopPropagation]="['click']" class="flex justify-center items-center" (click)="showNotification()">
      <img *ngIf="(isNewMessages | async) !== true" src="assets/icons/bell-notification.svg" alt="notification bell"
           class="h-7 w-7"/>
      <img *ngIf="isNewMessages | async" src="assets/icons/bell-notification-action.svg" alt="notification bell"
           class="h-7 w-7"/>
    </button>

    <mat-menu backdropClass="notificationMenu"
              [xPosition]="'after'"
              #userNotifications="matMenu" class="user-menu notificationMenu">
      <div class="bg-[#26153f] w-full h-20 absolute inset-0 rounded-t-md flex justify-between items-center py-7 px-6">
        <p class="text-xl text-white font-bold z-10">{{ t('NOTIFICATIONS')}}</p>
        <img src="assets/icons/open-mail.svg" alt="open mail" class="h-6 w-6"/>
      </div>
      <div class="mt-20" *ngIf="notifications.allRecords > 0; else noItems">
        <partial-notification-item (handleClick)="handleReadNotification($event)" [notification]="notification"
                                   *ngFor="let notification of notifications?.data; trackBy: trackByFn"></partial-notification-item>
        <button class="w-full my-1" *ngIf="size < notifications.allRecords" [stopPropagation]="['click']"
                (click)="loadMore()">{{ 'LOAD_MORE' | transloco}}</button>
      </div>

      <ng-template #noItems>
        <div class="min-w-100 px-6 py-3 mt-20">{{ 'NO_ITEMS' | transloco}}</div>
      </ng-template>
    </mat-menu>
  </div>
</ng-container>
