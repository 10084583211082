import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, HostListener, Inject, Input} from '@angular/core';
import {AppUtils} from '@tante-tobi-web/utils';

@Directive({
  selector: '[fullscreen]',
  exportAs: 'fullscreen'
})
export class FullscreenDirective {
  @Input() fullscreen: 'document' | 'current' | Element = 'document';
  get active() {
    return this.fullscrenElement;
  }
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly elementRef: ElementRef<HTMLElement>
  ) { }

  @HostListener('click')
  request() {
    let element: any;
    const document: any = this.document;
    switch (this.fullscreen) {
      case 'current':
        element = this.elementRef.nativeElement;
        break;
      case 'document':
        element = this.document.documentElement;
        break;
    }
    if (AppUtils.isNullOrUndefined(element)) {
      if (this.fullscreen instanceof Element) {
        element = this.fullscreen;
      } else {
        element = this.document.querySelector(this.fullscreen as any);
      }
    }
    const requestFullScreen =
      element.requestFullscreen || element['mozRequestFullScreen'] ||
      element['webkitRequestFullScreen'] || element['msRequestFullscreen'];
    const cancelFullScreen =
      this.document.exitFullscreen || document['mozCancelFullScreen'] ||
      document['webkitExitFullscreen'] || document['msExitFullscreen'];
    if (this.active) {
      cancelFullScreen.call(this.document);
    } else {
      requestFullScreen.call(element);
    }
  }

  get fullscrenElement() {
    const document: any = this.document;
    return (
      document.fullscreenElement ||
      document['mozFullScreenElement'] ||
      document['webkitFullscreenElement'] ||
      document['msFullscreenElement']
    );
  }

}
