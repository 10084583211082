import { AbstractControl, ValidatorFn } from '@angular/forms';
import { createValidator } from './validator_factory';

export const NumberOnly = (): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null => {
        if (control) {
            return isNaN(control.value) ? { not_number: true } : null;
        }
        return null;
    };


export const NotLessThan = (name: string, options?: {
    extra?: number;
    equality?: boolean;
}) => createValidator('NotLessThan', (control, parent) => {
        const opponentControl = Number(parent?.get(name)?.value);
        const controlValue = Number(control.value) + (options?.extra || 0);
        if (options?.equality) {
            return donotNan(controlValue) >= donotNan(opponentControl);
        }
        return donotNan(controlValue) > donotNan(opponentControl);
    });

export const NotGreaterThan = (name: string, options?: {
    extra?: number;
    equality?: boolean;
}) => createValidator('NotGreaterThan', (control, parent) => {
        const opponentControl = Number(parent?.get(name)?.value);
        const controlValue = Number(control.value) + (options?.extra || 0);
        if (options?.equality) {
            return donotNan(controlValue) <= donotNan(opponentControl);
        }
        return donotNan(controlValue) < donotNan(opponentControl);
    });
const donotNan = (value: any) => isNaN(value) ? 0 : value;
