  <ng-container *transloco="let t"> 

<div class="mt-20 mb-4 font-bold text-base text-teal-500">{{ t('BOOKING.PAYMENT_DETAILS') }}</div>

<form [formGroup]="paymentDetailsForm">
  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.PAYMENT_METHOD') }}</mat-label>
      <input id="paymentMethod" matInput formControlName="paymentMethod"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.AMOUNT') }}</mat-label>
      <input id="actualAmount" matInput formControlName="actualAmount"/>
    </mat-form-field>
  </div>

  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.TAX') }} (%)</mat-label>
      <input id="vat" matInput formControlName="vat"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.TAX_AMOUNT') }} ({{ t('BOOKING.AMOUNT') }} * {{ t('BOOKING.TAX') }} % )</mat-label>
      <input id="taxAmount" matInput formControlName="taxAmount"/>
    </mat-form-field>
  </div>

  <mat-form-field class="w-full">
    <mat-label>{{ t('BOOKING.TOTAL_AMOUNT') }}({{ t('BOOKING.AMOUNT') }} + {{ t('BOOKING.TAX_AMOUNT') }} )</mat-label>
    <input id="totalAmount" matInput formControlName="totalAmount"/>
  </mat-form-field>

</form>
  </ng-container>