import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {FuseConfirmationModule} from './services/confirmation';
import {FuseMediaWatcherModule} from './services/media-watcher';

@NgModule({
    imports: [
        FuseConfirmationModule,
        FuseMediaWatcherModule,
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide: MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme: false,
                version: true
            }
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
                floatLabel: 'always'
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom'
            }
        }
    ]
})
export class FuseModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: FuseModule) {
        if (parentModule) {
            throw new Error('FuseModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
