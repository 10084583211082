import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseField, Form, IRawFieldComponent} from '@ezzabuzaid/ngx-form-factory';
import {AppUtils, assertNotNullOrUndefined} from '@tante-tobi-web/utils';
import {merge, startWith, Subject, takeUntil} from 'rxjs';

@Component({
    selector: 'app-stepper-field',
    templateUrl: './stepper-field.component.html',
    styles: [
    ],
    host: {
        class: '-ml-6 block'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperFieldComponent implements OnInit, OnDestroy, IRawFieldComponent<string[]> {
    @Input() steps!: Form<any>[];
    @Input() label!: string;
    formControl!: BaseField<any[]>;
    private _subscription = new Subject();

    constructor(
        private cdf: ChangeDetectorRef
    ) { }

    ngOnDestroy(): void {
        AppUtils.unsubscribe(this._subscription);
    }

    ngOnInit(): void {
        assertNotNullOrUndefined(this.steps, 'StepperFieldComponent.steps');
        assertNotNullOrUndefined(this.formControl, 'StepperFieldComponent.formControl');
        // initialize steps form listener
        this._listenToValueChanges();
        this._listenToValidationChanges();
        this._listenToStatusChanges();
    }

    setStep(steps: Form<any>[]) {
        this.steps = steps;
        this.cdf.markForCheck();
        // re-initialize listener on steps changes
        this._subscription.next(null);
        this.formControl.setValue(null);
        this._listenToValueChanges();
        this._listenToValidationChanges();
        this._listenToStatusChanges();
    }

    private _listenToValueChanges() {
        this.steps.forEach((step, index) => {
            step.valueChanges
                .pipe(
                    takeUntil(this._subscription)
                )
                .subscribe((value) => {
                    const controlValue = this.formControl.value ?? [];
                    controlValue[index] = value;
                    this.formControl.setValue(controlValue);
                });
        });
    }

    private _listenToValidationChanges() {
        merge(
            ...this.steps.map(step => step.valueChanges),
            ...this.steps.map(step => step.statusChanges)
        )
            .pipe(
                takeUntil(this._subscription),
                startWith(null)
            )
            .subscribe(() => {
                const errors = this._aggregateErrors();
                this.formControl.setErrors(!!errors.length ? errors : null);
            });
    }

    private _listenToStatusChanges() {
        this.formControl.statusChanges
            .pipe(
                takeUntil(this._subscription),
                startWith(this.formControl.status)
            )
            .subscribe((status) => {
                if (status === 'DISABLED') {
                    this.steps.forEach(step => step.disable({emitEvent: false}));
                } else {
                    this.steps.forEach(step => step.enable({emitEvent: false}));
                }
            });
    }

    private _aggregateErrors() {
        return this.steps
            .reduce((acc, step, index) => {
                const stepErrors = Object.entries(step.controls)
                    .reduce((errors, [name, control]) => {
                        if (control.errors) {
                            errors[name] = control.errors;
                        }
                        return errors;
                    }, {} as any);
                if (Object.keys(stepErrors).length) {
                    acc.push(stepErrors);
                }
                return acc;
            }, [] as any);
    }

}
