import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiConstants} from '../../constants';
import {CalendarModel} from '../../models';

@Injectable({
	providedIn: 'root'
})
export class CalendarService {
	constructor(
		private _httpClient: HttpClient,
	) {
	}

	getSlots() {
		return this._httpClient.get<CalendarModel.Slot[]>(`${ApiConstants.CALENDER.slots}`);
	}

	addUnavailabileSlot(slots: CalendarModel.AddUnavailabileSlot[]) {
		return this._httpClient.patch<void>(`${ApiConstants.CALENDER.addUnavailabilityPeriod}`, {
			daySlots: slots
		});
	}

}
