import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AddClassDirective} from './add_class.directive';
import {DynamicComponentDirective} from './dynamic-component.directive';
import {FitContentDirective} from './fit-content.directive';
import {FullscreenDirective} from './fullscreen.directive';
import {LimitedTextDirective} from './limited-text.directive';
import {ScrollResetDirective} from './scroll-reset.directive';
import {ScrollbarDirective} from './scrollbar/scrollbar.directive';
import {StopPropagationDirective} from './stop-propagation/stop-propagation.directive';

const directives = [
  StopPropagationDirective,
  FullscreenDirective,
  AddClassDirective,
  ScrollResetDirective,
  ScrollbarDirective,
  DynamicComponentDirective,
  LimitedTextDirective,
  FitContentDirective
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: directives,
  exports: directives
})
export class DirectivesModule { }
