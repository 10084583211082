import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {BookingModel} from "@tante-tobi-web/infrastructure";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'partial-payment-info',
  templateUrl: './payment-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentInfoComponent implements OnInit {
  paymentDetailsForm: FormGroup
  @Input('row') paymentDetails!: BookingModel.Booking["paymentDetails"]

  constructor(private _fb: FormBuilder) {
    this.paymentDetailsForm = this.buildPaymentDetailsForm()
  }

  ngOnInit(): void {
    this.paymentDetailsForm.patchValue(this.paymentDetails)
    this.paymentDetailsForm.disable()
  }

  buildPaymentDetailsForm() {
    return this._fb.group({
      actualAmount: [''],
      vat: [''],
      taxAmount: [''],
      totalAmount: [''],
      paymentMethod: [''],
    })
  }

}
