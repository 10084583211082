import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DirectivesModule} from '@tante-tobi-web/shared';
import {BasicTableComponent} from './basic-table.component';
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    BasicTableComponent
  ],
  exports: [
    BasicTableComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    MatTableModule,
    MatIconModule,
  ]
})
export class BasicTableModule {
}
