import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AppGuard, RoutesConstants} from '@tante-tobi-web/infrastructure';
import {PageNotFoundComponent, PageNotFoundComponentModule} from '@tante-tobi-web/partials';
import {ContainerComponent} from './container/container.compnent';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'applications/dashboard',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: '',
    canActivate: [AppGuard],
    canActivateChild: [AppGuard],
    component: ContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'applications',
        pathMatch: 'full',
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./modules/applications/applications.module').then(
            (m) => m.ApplicationsModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'workshops',
        loadChildren: () =>
          import('./modules/workshops/workshops.module').then(
            (m) => m.WorkshopsModule
          ),
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('./modules/booking/booking.module').then(
            (m) => m.BookingModule
          ),
        data: { header: 'NAVIGATION.BOOKING' },
      },  {
        path: 'voucher',
        loadChildren: () =>
          import('./modules/voucher/voucher.module').then(
            (m) => m.VoucherModule
          ),
        data: { header: 'NAVIGATION.VOUCHER' },
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('./modules/statements/statements.module').then(
            (m) => m.StatementsModule
          ),
        data: { header: 'NAVIGATION.STATEMENTS' },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile-management/profile-management.module').then(
            (m) => m.ProfileManagementModule
          ),
      },
    ],
  },
  {
    path: RoutesConstants.NOT_FOUND.withoutSlash,
    component: PageNotFoundComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    PageNotFoundComponentModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
