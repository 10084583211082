import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {distinctUntilKeyChanged, pluck, share} from 'rxjs/operators';
import {ApplicationConstants} from '../constants';
@Injectable()
/**
 * Make sure to add it in @Component providers list
 */
export class RouteUtility {
    constructor(
        public route: ActivatedRoute
    ) { }

    onQueryParamChange(param: string) {
        return this.route.queryParams
            .pipe(
                distinctUntilKeyChanged(param),
                pluck<Params, string>(param),
                share()
            );
    }

    getQueryParam(param: string) {
        return this.route.snapshot.queryParamMap.get(param);
    }

    redirectUrl() {
        return this.route.snapshot.queryParamMap.get(ApplicationConstants.REDIRECT_URL);
    }

    getPageId() {
        return this.route.snapshot.paramMap.get('id');
    }

}
