import {IRequestOptions} from "./request-options";

// Add those lines as they are
declare module '@angular/common/http/http' {
	// Augment HttpClient with the added `configure` method
	export interface HttpClient {
		/**
		 * Configure request options.
		 */
		configure(options: Partial<IRequestOptions>): HttpClient;
	}
}
export * from './constants';
export * from './core.module';
export * from './guards';
export * from './interceptors';
export * from './models';
export * from './services';
export * from './states';
export * from './tokens';

