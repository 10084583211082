import { createValidator } from './validator_factory';

export const NotBeforeTime = (field: string) => {
    function validate(open: string, close: string) {
        if (!close || !open) {
            return false;
        }
        const _openDate = new Date();
        const _closeDate = new Date();
        _openDate.setHours(+open.split(':')[0]);
        _openDate.setMinutes(+open.split(':')[1]);
        _closeDate.setHours(+close.split(':')[0]);
        _closeDate.setMinutes(+close.split(':')[1]);
        return _openDate.getTime() <= _closeDate.getTime();
    }

    return createValidator('NotBeforeTime', (control, parent) => {
        const opponent = parent?.get(field)?.value;
        if (!opponent) {
            return true;
        }
        return validate(parent.get(field)?.value, control.value);
    });
};

export const NotAfterTime = (field: string) => {
    function validate(open: string, close: string) {
        if (!close || !open) {
            return false;
        }
        const _openDate = new Date();
        const _closeDate = new Date();
        _openDate.setHours(+open.split(':')[0]);
        _openDate.setMinutes(+open.split(':')[1]);
        _closeDate.setHours(+close.split(':')[0]);
        _closeDate.setMinutes(+close.split(':')[1]);
        return _openDate.getTime() >= _closeDate.getTime();
    }

    return createValidator('NotAfterTime', (control, parent) => {
        const opponent = parent?.get(field)?.value;
        if (!opponent) {
            return true;
        }
        return validate(opponent, control.value);
    });
};

export const BeforeDate = (field: string) => createValidator('BeforeDate', (control, parent) => {
        const opponent = parent?.get(field)?.value;
        if (!opponent) {
            return true;
        }
        return new Date(control.value).getTime() < new Date(opponent).getTime();
    });
export const AfterDate = (field: string) => createValidator('AfterDate', (control, parent) => {
        const opponent = parent?.get(field)?.value;
        if (!opponent) {
            return true;
        }
        return new Date(control.value).getTime() > new Date(opponent).getTime();
    });
