<mat-sidenav-container [ngClass]="{'below-nav' : !sidenav.opened}">
  <mat-sidenav
    #sidenav
    class="w-[725px] z-99999"
    mode="over"
    position="end"
    [opened]="false"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    (closed)="closed.emit()"
  >
    <ng-container *ngIf="sidenavContent">
      <ng-container [partialSidenavContent]="sidenavContent"></ng-container>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content class="flex flex-col justify-between bg-white">
    <table mat-table [dataSource]="dataSource" class="w-full h-full" multiTemplateDataRows>
      <tr style="display: none" mat-header-row *matHeaderRowDef="[]"></tr>
      <tr style="display: none" mat-row *matRowDef="let row; columns: [];"></tr>
      <ng-container *ngFor="let column of displayColumns">
        <ng-container [matColumnDef]="column.key">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-white {{column.class}}"
            [ngStyle]="{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width
            }"
          >
            {{ column.translate ? (column.title | transloco) : column.title }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [class]="column.class"
            [ngStyle]="{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width
            }"
          >
            <ng-container [ngSwitch]="column.type">
              <ng-container *ngSwitchCase="'component'">
                <ng-container
                  [dynamic-component]="column.component!"
                  [inputs]="{ row: element, cell: element[column.key] }"
                ></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'valueFormatter'">
                {{ column.valueFormatter!(element, element[column.key]) }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ element[column.key] }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="bg-slate-900 bg-opacity-70 h-13"
      ></tr>
      <tr
        class="h-13 "
        [ngClass]="{'hover:bg-gray-300/50 cursor-pointer' : !!tableSidenavContentDirective }"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="toggleSidenav(row)"
      ></tr>
      <tr *matNoDataRow>
        <td [attr.colspan]="displayedColumns.length" class="w-full h-full">
          <div class="flex flex-col items-center justify-center font-bold text-neutral-300 text-xl">
            <mat-icon class="text-9xl w-20 h-20 text-neutral-300">search_off</mat-icon>
            {{ 'ERRORS.ERRORS' | transloco }}
          </div>
        </td>
      </tr>
    </table>
    <mat-paginator
      class="sticky bottom-0 left-0 z-10"
      (page)="onPaginate($event)"
      [showFirstLastButtons]="true"
      [hidePageSize]="false"
      [pageSizeOptions]="[ 25, 50 ,100]"
      [pageSize]="25"
    >
    </mat-paginator>
  </mat-sidenav-content>
</mat-sidenav-container>
