import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutoCompleteFieldComponent} from './auto-complete-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
  declarations: [AutoCompleteFieldComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatAutocompleteModule, MatInputModule, TranslocoModule],
  exports: [AutoCompleteFieldComponent]
})
export class AutoCompleteFieldModule {
}
