export namespace MediaModel {

  export interface MediaResponse {
    id: number;
    mediaUrl: string;
    mimeMediaType: string;
    mediaType: string;
  }

  export interface DocumentsRequest {
    documentName: string;
    mediaId: number;
    name?: string;
  }

}
