<ng-container>
  <button class="notification-menu-item" mat-menu-item (click)="handleClick.emit(notification)"
          [class.opacity-50]="notification.isRead">
                    <span class="flex justify-between items-start">
                        <span class="flex gap-4 items-center">
                            <span class="rounded-full flex items-center justify-center w-10 min-w-10 h-10 bg-[#ebebeb]">
                                <img src="assets/icons/bell-notification.svg" alt="notification bell m-0"
                                     class="h-6 w-6"/>
                            </span>
                            <span class="flex flex-col">
                                <span class="line-clamp-2 font-bold text-base">{{notification.title}}</span>
                                <span class="break-words text-xs line-clamp-3">{{notification.message}}</span>
                            </span>
                        </span>
                        <span>
                         <mat-icon *ngIf="!notification.isRead"
                                  class="text-primary text-sm m-0">fiber_manual_record</mat-icon>
                        </span>
                    </span>
  </button>
  <mat-divider class="mx-6"></mat-divider>
</ng-container>
