import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {BookingModel} from "@tante-tobi-web/infrastructure";
import {Column} from "@tante-tobi-web/partials";

@Component({
  selector: 'partial-members',
  templateUrl: './members.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersComponent implements OnInit {
  @Input('row') members!: BookingModel.Booking['members'];
  displayColumns = [
    new Column({
      key: 'id',
      title: 'ID',
      minWidth: '80px',
      maxWidth: '80px',
      valueFormatter: (row, cell) => '#' + cell,
    }),
    new Column({
      key: 'email',
      title: 'Email',
      class: 'truncate',
      maxWidth: '175px',
    }),
    new Column({
      key: 'fullName',
      title: 'Full Name',
      class: 'truncate',
      maxWidth: '175px',
    }),
    new Column({
      key: 'organizationName',
      title: 'Organization',
      valueFormatter: (row, cell) => (cell ? cell : '---'),
      class: 'truncate',
      maxWidth: '175px',
    }),
  ];

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.displayColumns.map((item) => {
      switch (item.title) {
        case 'ID':
          this.translocoService
            .selectTranslate('BOOKING.ID')
            .subscribe((value) => {
              item.title = value;
            });
          break;
        case 'Email':
          this.translocoService
            .selectTranslate('BOOKING.EMAIL')
            .subscribe((value) => {
              item.title = value;
            });
          break;
        case 'Full Name':
          this.translocoService
            .selectTranslate('BOOKING.FULL_NAME')
            .subscribe((value) => {
              item.title = value;
            });
          break;
        case 'Organization':
          this.translocoService
            .selectTranslate('BOOKING.ORGANIZATION_NAME')
            .subscribe((value) => {
              item.title = value;
            });
          break;
        default:
          break;
      }
    });
  }
}
