<button
  mat-flat-button
  [color]="color"
  [ngClass]="buttonClass"
  [class.cursor-not-allowed]="disabled"
  [disabled]="disabled || loading"
  (click)="submit($event)"
>
  <span class="flex items-center gap-2">
    <mat-icon *ngIf="iconName">{{ iconName }}</mat-icon>
    <span>{{ label }}</span>
    <mat-progress-spinner *ngIf="loading" [diameter]="spinnerDiameter" [mode]="spinnerMode"> </mat-progress-spinner>
  </span>
</button>
