import {Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {WINDOW} from '@ng-web-apis/common';
import {AppUtils, createResizeObservable} from '@tante-tobi-web/utils';
import {Subject, takeUntil} from 'rxjs';
import {TableComponent} from './table.component';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'not-used-at-all-do-not-use'
})
export class TableHeightDirective implements OnInit, OnDestroy {
	private subscription = new Subject();

	constructor(
		private _elementRef: ElementRef<HTMLElement>,
		private _renderer: Renderer2,
		private _tableComponent: TableComponent,
		@Inject(WINDOW) private _window: Window
	) {

	}

	ngOnDestroy(): void {
		AppUtils.unsubscribe(this.subscription);
	}

	ngOnInit(): void {
		createResizeObservable(this.element)
			.pipe(takeUntil(this.subscription))
			.subscribe(() => {
				this._setHeight();
			});
	}

	get element() {
		return this._elementRef.nativeElement;
	}

	private _calculateHeight() {
		const {top} = this.element.getBoundingClientRect();
		const elementEdges = this._edges(this.element);
		const parentEdges = this._edges(this._tableComponent.element);
		return this._window.innerHeight - top - elementEdges - parentEdges;
	}

	private _edges(node: HTMLElement) {
		const list = [
			// 'margin-top',
			// 'border-top',
			// 'padding-top',
			'margin-bottom',
			'border-bottom',
			'padding-bottom',
		];

		const style = this._window.getComputedStyle(node);
		return list
			.map(k => parseInt(style.getPropertyValue(k), 10))
			.reduce((prev, cur) => prev + cur);
	}

	private _setHeight() {
		this._renderer.setStyle(this.element, 'height', `${this._calculateHeight()}px`);
	}

	@HostListener('window:resize')
	private _onWindowResize() {
		this._setHeight();
	}


}
