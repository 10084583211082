import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
// FIXME: to be removed
export class GatewayService {
	constructor(private httpClient: HttpClient) { }

	get(url: string, options?: any) {
		return this.httpClient.configure({fullResponse: true}).get(`${url}`, options);
	}

	post(url: string, data: any, option?: any) {
		return this.httpClient.configure({fullResponse: true}).post(`${url}`, data, option);
	}

	put(url: string, data: any, option?: any) {
		return this.httpClient.configure({fullResponse: true}).put(`${url}`, data, option);
	}

	patch(url: string, data: any, option?: any) {
		return this.httpClient.configure({fullResponse: true}).patch(`${url}`, data, option);
	}

	delete(url: string, option?: any) {
		return this.httpClient.configure({fullResponse: true}).delete(`${url}`, option);
	}


}
