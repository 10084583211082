import { TranslocoModule } from '@ngneat/transloco';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BookingStatusCellComponent} from "./booking-status-cell.component";


@NgModule({
  declarations: [
    BookingStatusCellComponent
  ],
  exports: [
    BookingStatusCellComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class BookingStatusCellModule { }
