import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {
  AbstractAuthService,
  AbstractUserService,
  AdminUser,
  InstructorUser,
  RoutesConstants
} from '@tante-tobi-web/infrastructure';
import {Subject} from 'rxjs';

@Component({
  selector: 'partial-user',
  templateUrl: './user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnDestroy {
  @Input() showAvatar = true;
  @Input() user!: AdminUser | InstructorUser;
  profileRoute = RoutesConstants.PROFILE.withoutSlash;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _authService: AbstractAuthService,
    private _userService: AbstractUserService
  ) {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  logout(): void {
    this._authService.logout().subscribe();
  }
}
