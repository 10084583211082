export namespace CalendarModel {
	export interface Slot {
		id: string;
		date: string;
		bookingId: string;
		status: 'AVAILABLE' | 'UNAVAILABLE';
	}

	export interface AddUnavailabileSlot {
		daySlot: string;
		action: 'AVAILABLE' | 'UNAVAILABLE';
	}

  export interface BookingSlot {
    from: string;
    to: string
  }
}
