import { MonoTypeOperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

/**
 * Options interface for {typeaheadOperator}
 */
interface ITypeaheadOperatorOptions {
    minLength: number;
    debounceTime: number;
}

/**
 * RxJS custom opeartor that will emits only after the passage of {options.debounceTime}.
 *
 * The event must be string otherwise the it won't emit
 *
 * @param options object that specifies operator behavior.
 */
export const typeaheadOperator = (options: ITypeaheadOperatorOptions): MonoTypeOperatorFunction<string> => source => source.pipe(
        debounceTime(options.debounceTime),
        filter(value => value !== null || value !== undefined),
        filter(value => typeof value === 'string'),
        filter(value => value.length >= options.minLength),
        map(value => value.toLowerCase()),
        distinctUntilChanged(),
    );
