import { Injectable } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class Connectivity {

    get isOnline() {
        return navigator.onLine;
    }

    get isOffline() {
        return !this.isOnline;
    }

    observe() {
        return merge(
            of(this.isOnline),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        )
            .pipe(share());
    }
}
