import {ApiConstants, CategoryModel, PaginationQuery, PaginationResult, StatementsModel } from '@tante-tobi-web/infrastructure';
import {HttpClient} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Observable} from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class StatementsService {
    constructor(
        private _httpClient: HttpClient,
    ) { }

    getStatementsList(query: PaginationQuery): Observable<PaginationResult<StatementsModel.Statements[]>> {
        return this._httpClient
            .configure({fullResponse: true})
            .get<PaginationResult<StatementsModel.Statements[]>>(`${ApiConstants.STATEMENTS.statements}?${query.asString()}`)
    }
    getAdminStatementsList(query: PaginationQuery): Observable<PaginationResult<StatementsModel.Statements[]>> {
        return this._httpClient
            .configure({fullResponse: true})
            .get<PaginationResult<StatementsModel.Statements[]>>(`${ApiConstants.STATEMENTS_ADMIN.statements}?${query.asString()}`)
    }
    getCategories() {
        return this._httpClient.get<CategoryModel.Category[]>(ApiConstants.CATEGORIES.getCategories);
    }
    getAdminCategories(){
        return this._httpClient.get<CategoryModel.Category[]>(ApiConstants.CATEGORIES_ADMIN.getCategories); 
    }
    getExport(start:string,end:string): Observable<Blob> {
        return this._httpClient.get(
          `${ApiConstants.STATEMENTS_ADMIN.export}?startDate=${start}&endDate=${end}`,
          {
            responseType: 'blob',
          }
        );
    }
}