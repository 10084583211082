export namespace NotificationModel {

  export interface Notification {
    id: number;
    title: string;
    message: string;
    isRead: boolean;
    notificationCategory: 'BOOKING' | 'INSTRUCTOR_WORKSHOP';
    notificationPayload: { bookingId?: number, instructorWorkshopId?: number };
    notificationType: 'INFO';
  }

  export interface InAPPNotification {
    title: string;
    body: string;
    image: boolean;
  }

  export interface Notifications {
    data: Notification[];
    allRecords: number;
  }

}

