import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FuseAlertComponent} from './alert.component';

@NgModule({
    declarations: [
        FuseAlertComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        FuseAlertComponent
    ],
})
export class FuseAlertModule { }
