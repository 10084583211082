export namespace StatementsModel{
    export interface Statements {
      id: number;
      workshopName: string;
      startingDate: Date;
      endingDate: Date;
      categoryName: string;
      instructorLastName?:string;
      totalAmount: number;
      tantetobiReceivables: number;
      tantetobiPayables: number;
    }
    export interface BookingDetails {
        workshopName: string;
        startingDate: Date;
        endingDate: Date;
        categoryName: string;
    }
    export interface PaymentDetails {
        totalAmount: number;
        tantetobiReceivables: number;
        tantetobiPayables: number
    }
    export interface DateRange{
        startingDate: Date;
        endingDate: Date;
    }
   

}