import {FormControl} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {AppUtils, assertNotNullOrUndefined} from '@tante-tobi-web/utils';
import {createValidator} from './validator_factory';

/**
 * Must match validator
 *
 * @param controlPath A dot-delimited string values that define the path to the control.
 * @param matchingControlPath A dot-delimited string values that define the path to the matching control.
 */
export const mustMatch = (controlPath: string, matchingControlPath: string) => createValidator('mustMatch', (formGroup) => {
  const control = formGroup.get(controlPath);
  const matchingControl = formGroup.get(matchingControlPath);

  if (!control || !matchingControl) {
    return false;
  }

  if (AppUtils.isEmptyString(matchingControl.value) || control.value !== matchingControl.value) {
    return false;
  }

  return true;
});

export const mustOneRequired = (controlPaths: string[]) => createValidator('mustOneRequired', (formGroup) => {
  const notExists = controlPaths.some((control) => !formGroup.get(control))
  const noValue = controlPaths.every((control) => !formGroup.get(control)?.value)
  return !(notExists || noValue);
});

export class NotMatchStateMatcher implements ErrorStateMatcher {
  constructor(private controlPath: string, private matchingControlPath: string) {
  }

  isErrorState(control: FormControl): boolean {
    assertNotNullOrUndefined(control);
    return (control && control?.parent?.get(this.controlPath)?.value !== control?.parent?.get(this.matchingControlPath)?.value
      && !!control?.parent?.get(this.matchingControlPath)?.dirty);
  }
}
