import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {DirectivesModule} from "@tante-tobi-web/shared";
import {LayoutVerticalNavigationAsideItemComponent} from './vertical/components/aside/aside.component';
import {LayoutVerticalNavigationBasicItemComponent} from './vertical/components/basic/basic.component';
import {LayoutVerticalNavigationCollapsableItemComponent} from './vertical/components/collapsable/collapsable.component';
import {LayoutVerticalNavigationDividerItemComponent} from './vertical/components/divider/divider.component';
import {LayoutVerticalNavigationGroupItemComponent} from './vertical/components/group/group.component';
import {LayoutVerticalNavigationSpacerItemComponent} from './vertical/components/spacer/spacer.component';
import {LayoutVerticalNavigationComponent} from './vertical/vertical.component';
@NgModule({
    declarations: [
        LayoutVerticalNavigationAsideItemComponent,
        LayoutVerticalNavigationBasicItemComponent,
        LayoutVerticalNavigationCollapsableItemComponent,
        LayoutVerticalNavigationDividerItemComponent,
        LayoutVerticalNavigationGroupItemComponent,
        LayoutVerticalNavigationSpacerItemComponent,
        LayoutVerticalNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DirectivesModule,
        TranslocoModule,


        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
    ],
    exports: [
        LayoutVerticalNavigationComponent
    ]
})
export class LayoutNavigationModule {
}
