import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {TableComponent} from '../table';

@Component({
    selector: 'app-sidenav-details-header',
    templateUrl: './sidenav-details-header.component.html',
    styles: [
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavDetailsHeaderComponent implements OnInit {
    public editMode = false;
    @Input() showEdit?= true;
    @Input() title?: string;
    @Input() subtitle?: string;
    @Output() editChange = new EventEmitter();


    constructor(
        @Optional() private table: TableComponent,
        @Optional() private sidenav: MatSidenav,
    ) { }


    ngOnInit(): void { }

    close() {
        if (this.table) {
            this.table.closeSidenav();
        } else if (this.sidenav) {
            this.sidenav.close();
        }
    }

    edit() {
        this.editChange.emit();
        this.editMode = !this.editMode;
    }

}
