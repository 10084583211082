<ng-container *transloco="let t">

<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative" *ngIf="user">
    <img
      class="w-7 h-7 rounded-full"
      *ngIf="showAvatar && user.imageUrl"
      [src]="user.imageUrl"
    />
    <mat-icon *ngIf="!showAvatar || !user.imageUrl">account_circle</mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>{{t ('PROFILE.LOOGED_IN_AS')}}</span>
      <span class="mt-1.5 text-md font-medium">{{
        user?.email
      }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <a mat-menu-item [routerLink]="profileRoute">
    <mat-icon>account_circle</mat-icon>
    <span>{{ t ('PROFILE.PROFILE')}}</span>
  </a>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{t ('PROFILE.SIGN_OUT')}}</span>
  </button>
</mat-menu>
</ng-container>
