import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RequestOptions} from '@ezzabuzaid/ngx-request-options';
import {finalize, Observable, tap} from 'rxjs';
import {IRequestOptions} from '../../request-options';
import {ProgressBarState} from '../../states';
import {TranslocoService} from "@ngneat/transloco";

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  private requestStack: HttpRequest<any>[] = [];
  private showSnackbar = true;
  constructor(
    private snackbar: MatSnackBar,
    private _progressBarState: ProgressBarState,
    private requestOptions: RequestOptions<IRequestOptions>,
    private _translocoService: TranslocoService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const snackbarOptions = this.requestOptions.get(request, 'snackbar');
    this.showSnackbar = !!snackbarOptions && request.method !== 'GET';
    if (this.showSnackbar) {
      Promise.resolve(null).then(() => this.snackbar.open('Please wait', '', {
        duration: Number.MAX_VALUE,
      }));
    }

    if (this.requestOptions.get(request, 'progressBar')) {
      this.requestStack.push(request);
      this._progressBarState.show();
    }

    return next.handle(request)
      .pipe(
        tap((response) => {
          if (response instanceof HttpResponse && this.showSnackbar) {
            const {message = null} = response.body;
            if (snackbarOptions?.successMessage || message) {
              this.snackbar.open(this._translocoService.translate(snackbarOptions?.successMessage || 'SUCCESS.SUCCESS') ?? message, '', {
                panelClass: ['bg-green-500', 'text-white', 'text-bold'],
              });
            } else{
              this.snackbar.dismiss();
            }
          }
        }),
        finalize(() => {
          this.requestStack.splice(this.requestStack.indexOf(request), 1);
          if (this.requestStack.length < 1) {
            this._progressBarState.hide();
          }
        }),
      );
  }
}
