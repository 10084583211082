import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ButtonComponent} from './button.component';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatButtonModule, MatIconModule],
})
export class ButtonModule {}
