import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {NotificationModel} from "@tante-tobi-web/infrastructure";

@Component({
  selector: 'partial-notification-toast',
  templateUrl: './notification-toast.component.html',
  host: {class: 'bg-white'}

})
export class NotificationToastComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: NotificationModel.InAPPNotification) {
  }

}
