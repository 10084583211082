import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {SnackbarComponent} from './snackbar.component';
import {TranslocoModule} from "@ngneat/transloco";



@NgModule({
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        TranslocoModule
    ]
})
export class SnackbarModule { }
