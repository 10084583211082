<ng-container *transloco="let t">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input type="text"
           [placeholder]="placeholder"
           matInput
           [formControl]="formControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" [id]="option.id">
        {{option.name}}
      </mat-option>
      <mat-option *ngIf="formControl.value" class="text-teal-600 text-md" [value]="formControl.value">
        {{t('WORKSHOP.CLICK_TO_SUGGEST')}} "{{formControl.value}}"
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControl.hasError('required')">
      {{ t('VALIDATION.REQUIRED')}}
    </mat-error>
  </mat-form-field>
</ng-container>
