import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import {PipesModule} from '@tante-tobi-web/shared';
import {ButtonModule} from '@tante-tobi-web/widgets';
import {PartialChangePasswordComponent} from "./change-password.component";


@NgModule({
  declarations: [PartialChangePasswordComponent],
  exports: [PartialChangePasswordComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    PipesModule,
    ButtonModule,
    TranslocoModule,
  ],
})
export class ChangePasswordModule {}
