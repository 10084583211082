import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[limitedText]'
})
export class LimitedTextDirective implements AfterViewInit {
  @Input('maxLength') maxLength = 7;


  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) {
  }

  ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement;
    let text = el.innerText;
    const length = text.length;

    if (length > this.maxLength) {
      text = text?.substring(0, this.maxLength) + '...';
    }
    el.innerText = text;
  }
}
