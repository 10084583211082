import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationToastComponent} from './notification-toast/notification-toast.component';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';
import {NotificationModel, StorageService} from "@tante-tobi-web/infrastructure";
import {NotificationService} from "@tante-tobi-web/infrastructure";

@Component({
  selector: 'partial-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  host: {class: 'block'}
})
export class NotificationsMenuComponent implements OnInit {
  @Input() notifications!: NotificationModel.Notifications;
  @Output() refresh = new EventEmitter<number>();
  @Output() readNotification = new EventEmitter<number>();
  size = 20;
  isNewMessages = new BehaviorSubject(false);

  constructor(private _router: Router,
              private _afMessaging: AngularFireMessaging,
              private _snackbar: MatSnackBar,
              private _storageService: StorageService,
              private _notificationService: NotificationService) {
  }

  ngOnInit(): void {
    const isNewMessage = JSON.parse(this._storageService.getItem('newNotifications') || 'null');
    this.listenToNewMessages();
    this.isNewMessages.next(isNewMessage);
  }

  handleReadNotification(notification: NotificationModel.Notification): void {
    this._notificationService.readNotifications([notification.id]).subscribe({next: () =>  this.refresh.emit()})
    if (notification.notificationCategory === 'BOOKING') {
      this._router.navigate(['bookings', 'list'], {queryParams: {id: notification.notificationPayload.bookingId}});
    }
    if (notification.notificationCategory === 'INSTRUCTOR_WORKSHOP') {
      this._router.navigate(['workshops', 'engaged'], {queryParams: {id: notification.notificationPayload.instructorWorkshopId}});
    }
  }

  trackByFn(index: number, notification: NotificationModel.Notification): number {
    return notification.id;
  }

  loadMore(): void {
    this.size = this.size + 20;
    this.refresh.emit(this.size);
  }


  listenToNewMessages(): void {
    // Follow here for more info: https://github.com/angular/angularfire/blob/master/docs/messaging/messaging.md
    this._afMessaging.messages.subscribe((payload) => {
      this._storageService.setItem('newNotifications', JSON.stringify(true));
      this.refresh.emit(this.size);
      this.isNewMessages.next(true);
      this._snackbar.openFromComponent(NotificationToastComponent,
        {data: payload.notification, duration: 3000, horizontalPosition: 'right', panelClass: 'bg-white'});
    });
  }

  showNotification(): void {
    this.isNewMessages.next(false);
    this._storageService.setItem('newNotifications', JSON.stringify(false));
  }

}
