import {AfterViewInit, ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AppUtils, assertNotNullOrUndefined} from '@tante-tobi-web/utils';
import {Subject, takeUntil} from 'rxjs';

@Directive({
    selector: '[partialSidenavContent]'
})
export class TableSidenavContentDirective implements AfterViewInit, OnDestroy {
    @Input('partialSidenavContent') tableSidenavContent!: TemplateRef<any>;
    private subscription = new Subject();
    constructor(
        private viewContainerRef: ViewContainerRef,
        private sidenav: MatSidenav,
        private cdf: ChangeDetectorRef
    ) { }

    ngAfterViewInit(): void {
        assertNotNullOrUndefined(this.tableSidenavContent);
        this.sidenav.openedChange
            .pipe(takeUntil(this.subscription))
            .subscribe((opened) => {
                this.viewContainerRef.clear();
                this.cdf.markForCheck();
            });
    }

    toggle(row: Record<string, any>) {
        this.sidenav.toggle();
        const subscription = this.sidenav.openedChange
            .subscribe((opened) => {
                if (opened) {
                    this.viewContainerRef.createEmbeddedView(this.tableSidenavContent, {row});
                    this.cdf.markForCheck();
                }
                subscription.unsubscribe();
            });
    }

    close() {
        this.sidenav.close();
    }

    ngOnDestroy(): void {
        AppUtils.unsubscribe(this.subscription);
    }
}
