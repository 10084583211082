<ng-container *transloco="let t"> 

<div
  class="flex flex-col items-start justify-start flex-auto min-w-0 h-full py-8 px-16"
>
  <div class="text-3xl font-bold pb-16">{{t ('PROFILE.CHANGE_PASSWORD')}}</div>

  <div class="flex items-start justify-start w-full flex-auto">
    <form class="mt-8 w-full" [formGroup]="changePasswordForm">
      <div class="mb-4">
        <mat-form-field class="w-full">
          <mat-label>{{t ('PROFILE.OLD_PASSWORD')}}</mat-label>
          <input
            id="oldPassword"
            matInput
            type="password"
            [formControlName]="'oldPassword'"
            #oldPasswordField
          />
          <button
            mat-icon-button
            type="button"
            matSuffix
            (click)="
              oldPasswordField.type === 'password'
                ? (oldPasswordField.type = 'text')
                : (oldPasswordField.type = 'password')
            "
          >
            <mat-icon
              class="icon-size-5"
              *ngIf="oldPasswordField.type === 'password'"
              >visibility</mat-icon
            >
            <mat-icon
              class="icon-size-5"
              *ngIf="oldPasswordField.type === 'text'"
              >visibility_off</mat-icon
            >
          </button>
          <mat-error
            *ngIf="changePasswordForm.get('oldPassword')?.hasError('required')"
          >
            {{t ('PROFILE.PASSWORD_IS_REQUIRED')}}
          </mat-error>
          <mat-error
            *ngIf="
              changePasswordForm.get('oldPassword')?.hasError('minlength') ||
              changePasswordForm.get('oldPassword')?.hasError('pattern')
            "
          >
            {{t ('PROFILE.PASSWORD_MINIMUM')}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-4">
        <mat-form-field class="w-full">
          <mat-label>{{t ('PROFILE.NEW_PASSWORD')}}</mat-label>
          <input
            id="newPassword"
            matInput
            type="password"
            [formControlName]="'newPassword'"
            #newPasswordField
          />
          <button
            mat-icon-button
            type="button"
            matSuffix
            (click)="
              newPasswordField.type === 'password'
                ? (newPasswordField.type = 'text')
                : (newPasswordField.type = 'password')
            "
          >
            <mat-icon
              class="icon-size-5"
              *ngIf="newPasswordField.type === 'password'"
              >visibility</mat-icon
            >
            <mat-icon
              class="icon-size-5"
              *ngIf="newPasswordField.type === 'text'"
              >visibility_off</mat-icon
            >
          </button>
          <mat-error
            *ngIf="changePasswordForm.get('newPassword')?.hasError('required')"
          >
            {{t ('PROFILE.PASSWORD_IS_REQUIRED')}}
          </mat-error>
          <mat-error
            *ngIf="
              changePasswordForm.get('newPassword')?.hasError('minlength') ||
              changePasswordForm.get('newPassword')?.hasError('pattern')
            "
          >
            {{t ('PROFILE.PASSWORD_MINIMUM')}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-4">
        <mat-form-field class="w-full">
          <mat-label>{{t ('PROFILE.CONFIRM_NEW_PASSWORD')}}</mat-label>
          <input
            id="confirmPassword"
            matInput
            type="password"
            [formControlName]="'confirmPassword'"
            #confirmNewPasswordField
            [errorStateMatcher]="confirmPasswordMatcher"
          />
          <button
            mat-icon-button
            type="button"
            matSuffix
            (click)="
              confirmNewPasswordField.type === 'password'
                ? (confirmNewPasswordField.type = 'text')
                : (confirmNewPasswordField.type = 'password')
            "
          >
            <mat-icon
              class="icon-size-5"
              *ngIf="confirmNewPasswordField.type === 'password'"
              >visibility</mat-icon
            >
            <mat-icon
              class="icon-size-5"
              *ngIf="confirmNewPasswordField.type === 'text'"
              >visibility_off</mat-icon
            >
          </button>
          <mat-error
            *ngIf="
              changePasswordForm.get('confirmPassword')?.hasError('required')
            "
          >
            {{t ('PROFILE.PASSWORD_IS_REQUIRED')}}
          </mat-error>
          <mat-error
            *ngIf="
              changePasswordForm
                .get('confirmPassword')
                ?.hasError('minlength') ||
              changePasswordForm.get('confirmPassword')?.hasError('pattern')
            "
          >
            {{t ('PROFILE.PASSWORD_MINIMUM')}}
          </mat-error>
          <mat-error *ngIf="changePasswordForm?.hasError('mustMatch')">
            {{t ('PROFILE.PASSWORDS_NOT_SAME')}}
          </mat-error>
        </mat-form-field>
      </div>
      <widget-button
        label="{{t ('PROFILE.CHANGE_PASSWORD')}}"
        [disabled]="isFormValid"
        (handleSubmit)="changePassword()"
        [loading]="!!changePasswordForm?.disabled"
        buttonClass="mt-6"
      ></widget-button>
    </form>
  </div>
</div>
</ng-container>