export namespace CategoryModel {
    export interface Category {
        id: number;
        isEnabled: boolean;
        creationDate: Date;
        thumbnailUrl: string;
        thumbnailId: number;
        name: string;
        isComingSoon: boolean;
        orderId: number;
    }

    export interface SubCategory {
        id: number;
        isEnabled: boolean;
        name: string;
        thumbnailUrl: string;
        thumbnailId: number;
    }

    export interface Topic {
        id: number;
        isEnabled: boolean;
        name: string;
        thumbnailUrl: string;
        thumbnailId: number;
        subCategoryId: number;
    }

}
