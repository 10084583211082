<ng-container>
    <button class="notification-menu-item" mat-menu-item>
                    <span class="flex justify-between items-start gap-10">
                        <span class="flex gap-4 items-center">
                            <span class="rounded-full flex items-center justify-center w-10 min-w-10 h-10"
                                  style="background-color: #49306c">
                                <img src="{{notification.image}}" alt="notification bell m-0"
                                     class="h-6 w-6"/>
                            </span>
                            <span class="flex flex-col">
                                <span class="truncate text-lg font-bold">{{notification.title}}</span>
                                <span class="break-words text-base line-clamp-3">{{notification.body}}</span>
                            </span>
                        </span>
                    </span>
    </button>
</ng-container>
