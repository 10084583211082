<!-- Navigation -->
<partial-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center w-full p-4 pl-6">
      <!-- Logo -->
      <div class="flex items-center justify-center">
        <a routerLink="/">
        <img class="w-26" src="assets/images/logo/logo.svg" />
        </a>
      </div>
      <!-- Components -->
      <div class="flex items-center ml-auto">
        <partial-user *ngIf="user" [showAvatar]="false" [user]="user"></partial-user>
      </div>
    </div>
    <!-- User -->
    <div class="flex flex-col items-center w-full p-4" *ngIf="user">
      <div class="relative w-24 h-24">
        <img
          class="object-scale-down w-full h-full rounded-full ring-1 ring-gray-200"
          *ngIf="user.imageUrl"
          [src]="user.imageUrl"
          alt="User avatar"
        />
        <mat-icon
          class="icon-size-24"
          *ngIf="!user.imageUrl"
          [svgIcon]="'heroicons_solid:user-circle'"
        ></mat-icon>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-6">
        <div
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium"
        >
          {{ user.firstName }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
        >
          {{ applicationName }}
        </div>
      </div>
    </div>
  </ng-container>
</partial-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="sticky top-0 flex flex-0 items-center w-full h-16 px-4 md:px-6 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden z-[15]"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon>menu</mat-icon>
    </button>
    <ng-container *transloco="let t">
      <div *ngIf="header" class="text-3xl font-bold ml-4">{{t(header)}}</div>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <partial-notifications-menu *ngIf="app === 'INSTRUCTOR'" [notifications]="$any(notifications$ | async)"
                                  (refresh)="triggerRefresh.next($event)"></partial-notifications-menu>
      <widget-language-selector></widget-language-selector>
      <button
        class="hidden md:block"
        mat-icon-button
        fullscreen="document"
        #fullscreen="fullscreen"
        [matTooltip]="'Toggle Fullscreen'"
      >
        <mat-icon>{{
          fullscreen.active ? 'fullscreen_exit' : 'zoom_out_map'
        }}</mat-icon>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <ng-content></ng-content>
  </div>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>
