import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {BookingModel} from "@tante-tobi-web/infrastructure";
import {startCase} from "lodash-es";

@Component({
  selector: 'partial-booking-status-cell',
  templateUrl: './booking-status-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingStatusCellComponent {
  @Input('row') row?: BookingModel.Booking
  @Input('cell') status?: BookingModel.Booking['status']
  statusClassMapper = {
    'ONGOING' : 'bg-[#2196f3]',
    'COMPLETED': 'bg-[#43a047]',
    'UPCOMING': 'bg-[#ff8f00]',
    'CANCELED': 'bg-red-400'
  }
  constructor() { }




}
