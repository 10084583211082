import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RequestOptions} from '@ezzabuzaid/ngx-request-options';
import {thisOrThat} from '@tante-tobi-web/utils';
import {SnackbarComponent} from '@tante-tobi-web/widgets';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, mapTo, switchMap, take, tap} from 'rxjs/operators';
import {ApiConstants} from '../../constants';
import {IRequestOptions} from '../../request-options';
import {AbstractAuthService, TokenManager} from '../../services';
import {Environment, ENVIRONMENT} from '../../tokens';

@Injectable()
export class TeardownInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private readonly requestQueue = new BehaviorSubject(false);

    constructor(
        private _authService: AbstractAuthService,
        private _tokenManager: TokenManager,
        private _snackbar: MatSnackBar,
        private _requestOptions: RequestOptions<IRequestOptions>,
        @Inject(ENVIRONMENT) private _environment: Environment
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = request.headers.set('Authorization', `Bearer ${this._tokenManager.accessToken}`);
        return next.handle(this._requestOptions.clone(request, {headers}))
            .pipe(
                catchError((event: HttpErrorResponse) => {
                    const snackbarOptions = this._requestOptions.get(request, 'snackbar');
                    if (event instanceof HttpErrorResponse) {
                        switch (event.status) {
                            case 401:
                                return this.#tryRefreshToken(event).pipe(switchMap(() => this.intercept(request, next)));
                            case 403:
                                this._authService.logout().subscribe();
                                return throwError(() => event);
                            case 500:
                                this._snackbar.open('Internal server error. Please try again later.');
                                break;
                            case 404:
                                if (event instanceof HttpErrorResponse && snackbarOptions) {
                                    const message = snackbarOptions?.errorMessage ?? event.error?.message;
                                    if (message) {
                                        this._snackbar.openFromComponent(SnackbarComponent, {
                                            duration: +Infinity,
                                            panelClass: ['warn', 'bg-red-500'],
                                            data: {
                                                text: message ?? 'NOT_CODED'
                                            }
                                        });
                                    }
                                }
                              return throwError(() => event.error?.message);
                              break;
                            case 0:
                                this._snackbar.open('Please check your internet then try again.');
                                break;
                            default:
                                if (event instanceof HttpErrorResponse) {
                                    const message = snackbarOptions?.errorMessage ?? event.error?.message;
                                    if (this._environment.production && !message) {
                                    } else {
                                        this._snackbar.openFromComponent(SnackbarComponent, {
                                            duration: +Infinity,
                                            panelClass: ['warn', 'bg-red-500'],
                                            data: {
                                                text: message ?? 'NOT_CODED'
                                            }
                                        });
                                    }
                                }
                              return throwError(() => event.error?.message);
                        }
                    }
                    return throwError(() => event);
                }),
            );
    }

    #refreshToken() {
        if (this.isRefreshing) {
            return this.requestQueue.asObservable()
                .pipe(
                    filter(it => !!it),
                    take(1)
                );
        } else {
            this.isRefreshing = true;
            this.requestQueue.next(false);
            return this._authService.refreshToken()
                .pipe(
                    tap(() => {
                        this.isRefreshing = false;
                        this.requestQueue.next(true);
                    }),
                    mapTo(true)
                );
        }
    }

    #tryRefreshToken(event: HttpErrorResponse) {
        const blackList = Object.values(ApiConstants.AUTH);
        return thisOrThat(
            () => !blackList.some(url => event.url?.includes(url)),
            () => this.#refreshToken(),
            () => this.#stopRefreshing(event)
        ).pipe(catchError(() => this.#stopRefreshing(event)));
    }

    #stopRefreshing(event: HttpErrorResponse) {
        this.isRefreshing = false;
        this._authService.logout();
        return throwError(() => event.error?.message);
    }

}
