<div class="flex justify-between">
  <p mat-dialog-title>
    <span *ngIf="data?.title">
      {{ data?.title }}
    </span>
  </p>
  <button class="relative bottom-px" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p *ngIf="data?.description">{{ data?.description }}</p>
  <ng-container *ngIf="data?.template as template">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </ng-container>
</div>
<div mat-dialog-actions align="end" class="mt-4" *ngIf="data.close">
  <button cdkFocusInitial mat-flat-button (click)="onNoClick()">
    {{ data.close }}
  </button>
</div>
