import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import {BaseField, IRawFieldComponent} from '@ezzabuzaid/ngx-form-factory';
import {AppUtils, assertNotNullOrUndefined} from "@tante-tobi-web/utils";
import {map, Observable, shareReplay, startWith, Subject, takeUntil, tap} from "rxjs";

@Component({
  selector: 'widget-auto-complete-field',
  templateUrl: './auto-complete-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteFieldComponent implements OnInit, OnDestroy, IRawFieldComponent<string[]> {
  @Input() label!: string;
  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() filteredOptions!: Observable<Array<any>>;
  formControl!: BaseField<any[]>;
  options!: Array<any>
  private _subscription = new Subject();

  constructor() {
  }

  ngOnInit(): void {
    assertNotNullOrUndefined(this.formControl, 'AutoCompleteFieldComponent.formControl');
    this.filteredOptions
      .pipe(shareReplay(1), tap( v => this.options = v.slice()))
      .subscribe({
        next: () => {
          this.filteredOptions = this.formControl.valueChanges
            .pipe(
              startWith(''),
              takeUntil(this._subscription),
              map(value => value ? this._filter(value?.name || value) : this.options))
        }
      })
  }

  displayFn(item: any): string {
    return item?.name || item || '';
  }

  private _filter(value: string) {
    return this.options.filter(option => option.name?.toLowerCase().includes(value?.toLowerCase()))
  }

  ngOnDestroy(): void {
    AppUtils.unsubscribe(this._subscription);
  }
}
