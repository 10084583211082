import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'widget-status-cell',
  templateUrl: './status-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusCellComponent {
  @Input('row') row?: any
  @Input('cell') status?: boolean
}
