import {FuseNavigationItem} from "@tante-tobi-web/partials";

export default [
  {
    id: 'application',
    title: 'NAVIGATION.APPLICATION',
    type: 'group',
    children: [
      {
        isActiveMatchOptions: {
          fragment: 'exact',
          matrixParams: 'exact',
          paths: 'exact',
          queryParams: 'exact',
        },
        id: 'application.dashboard',
        title: 'NAVIGATION.DASHBOARD',
        type: 'basic',
        image: 'dashboard.png',
        link: '/applications/dashboard',
      },
      {
        id: 'application.category',
        title: 'NAVIGATION.CATEGORY',
        type: 'basic',
        image: 'category.png',
        link: '/applications/categories',
      },
      {
        id: 'application.topics',
        title: 'NAVIGATION.TOPICS',
        type: 'basic',
        image: 'sub-category.png',
        link: '/applications/topics',
      },
    ],
  },
  {
    id: 'users',
    title: 'NAVIGATION.USERS',
    type: 'group',
    children: [
      {
        id: 'users.admin',
        title: 'NAVIGATION.ADMIN',
        type: 'basic',
        link: '/users/admin',
        image: 'admin.png',
      },
      {
        id: 'users.instructors',
        title: 'NAVIGATION.INSTRUCTORS',
        type: 'basic',
        link: '/users/instructors',
        image: 'instructor.png',
      },
      {
        id: 'users.clients',
        title: 'NAVIGATION.CLIENTS',
        type: 'basic',
        image: 'client.png',
        link: '/users/clients',
      },
    ],
  },
  {
    id: 'workshop_management',
    title: 'NAVIGATION.WORKSHOP_MANAGEMENT',
    type: 'group',
    children: [
      {
        id: 'workshop_management.workshop',
        title: 'NAVIGATION.WORKSHOP',
        type: 'basic',
        image: 'workshop.png',
        link: '/workshops/list',
      },
      {
        id: 'workshop_management.workshop-suggestions',
        title: 'NAVIGATION.WORKSHOP_SUGGESTION',
        type: 'basic',
        image: 'workshop.png',
        link: '/workshops/suggestions',
      },
      {
        id: 'workshop_management.workshop-request',
        title: 'NAVIGATION.ENGAGEMENT_REQUEST',
        type: 'basic',
        image: 'workshop.png',
        link: '/workshops/request',
      },
      {
        id: 'workshop_management.vouchers',
        title: 'NAVIGATION.VOUCHER',
        type: 'basic',
        image: 'workshop.png',
        link: '/voucher/list',
      }
    ],
  },
  {
    title: 'NAVIGATION.BOOKING',
    type: 'group',
    children: [
      {
        id: 'booking',
        title: 'NAVIGATION.BOOKING_WORKSHOP',
        type: 'basic',
        image: 'dashboard.png',
        link: '/booking',
      },
    ],
  },
  {
    id: 'finance',
    title: 'NAVIGATION.FINANCE',
    type: 'group',
    children: [
      {
        id: 'statements',
        title: 'NAVIGATION.STATEMENTS',
        type: 'basic',
        image: 'statements.png',
        link: '/finance/statements',
      },
    ],
  },
] as FuseNavigationItem[];
