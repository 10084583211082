import {Injectable} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ResolveStart, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay, filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarState {
    #state = new BehaviorSubject(false);
    constructor(
        private router: Router
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart || event instanceof ResolveStart))
            .subscribe(() => {
                this.show();
            });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
            .subscribe(() => {
                this.hide();
            });
    }

    show() {
        this.#state.next(true);
    }

    hide() {
        this.#state.next(false);
    }

    onVisibilityChange() {
        return this.#state.asObservable().pipe(delay(0));
    }
}

export abstract class AbstractState {
    abstract select(): Observable<any>;
}
