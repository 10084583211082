import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { IAlertPopup } from './Ialert';
import { IConfirmPopup } from './Iconfirm';
import { IPromptPopup } from './Iprompt';
import { PromptComponent } from './prompt/prompt.component';

@Injectable()
export class PopupManager {
  private readonly defaultConfig: MatDialogConfig<any> = { width: '450px' };
  constructor(
    private readonly dialog: MatDialog
  ) { }

  alert(config: MatDialogConfig<IAlertPopup> = {}) {
    return this.dialog.open<AlertComponent, IAlertPopup, void>(AlertComponent, this.populateDialogConfig(config));
  }

  prompt(config: MatDialogConfig<IPromptPopup> = {}) {
    return this.dialog.open<PromptComponent, IPromptPopup, string>(PromptComponent, this.populateDialogConfig(config));
  }

  confirm(config: MatDialogConfig<IConfirmPopup> = {}) {
    return this.dialog.open<ConfirmComponent, IConfirmPopup, boolean>(ConfirmComponent, this.populateDialogConfig(config));
  }

  private populateDialogConfig(config: MatDialogConfig<any>) {
    return { ...this.defaultConfig, ...config, };
  }

}



