import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotificationModel} from '../../models';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private _httpClient: HttpClient,
  ) {
  }

  getNotifications(size: number = 20): Observable<NotificationModel.Notifications> {
    const params = new HttpParams().append('size', size);
    return this._httpClient.configure({fullResponse: true}).get<NotificationModel.Notifications>('instructor/notifications', {params});
  }

  readNotifications(notificationIds: number[]): Observable<any> {
    return this._httpClient.patch('instructor/notifications/read', {notificationIds});
  }

}
