import {Observable} from 'rxjs';
import {ApiConstants, TokenManager} from '@tante-tobi-web/infrastructure';
import {AuthModel, AbstractAuthService} from '@tante-tobi-web/infrastructure';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class AuthService extends AbstractAuthService {
  constructor(protected override _httpClient: HttpClient,
              protected override _tokenManager: TokenManager,
              protected override _router: Router) {
    super(_httpClient, _tokenManager, _router);
  }

  override userRoles : AuthModel.UserRoles[] = ['ADMIN', 'SUPER_ADMIN']

  override changePassword(data: AuthModel.ChangePasswordRequest): Observable<any> {
    return this._httpClient.configure({
      snackbar: {successMessage: 'SUCCESS'}
    }).patch(ApiConstants.ADMIN_PROFILE.changePassword, data);
  }

}
