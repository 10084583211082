import {Injectable} from '@angular/core';
import {GatewayService} from '../gateway.service';

@Injectable({
	providedIn: 'root'
})
export class BusinessService {

	constructor(private gatewayService: GatewayService) { }

	createFile(file: any) {
		return this.gatewayService.post('media/upload', file);
	}

	updateFile(fileId: string, file: any) {
		return this.gatewayService.patch(`media/upload/${fileId}`, file);
	}

  showHideFile(userId: number, userDocumentId: string | number, isHidden: boolean) {
    return this.gatewayService.patch(`admin/instructors/documents`, {userId, userDocumentId, isHidden});
  }

  // get documents types based on country and role: cv, cover letter, etc..
  // because they will be different based on each country and role.
  // Example: in spain they require only cv and cover letter, but in jordan they require cv, cover letter and id
  getDocuments(countryId = 1, roleId = 3) {
		return this.gatewayService.get('documents', {params: {countryId, roleId}});
	}
}
