import {Observable, ReplaySubject, tap} from "rxjs";
import {ApplicationUser} from "../../models";

export abstract class AbstractUserService {
	protected _user = new ReplaySubject<ApplicationUser>(1);
	private instantUser?: ApplicationUser;


	set user(value: ApplicationUser | undefined) {
		this._user.next(value!);
		this.instantUser = value;
	}

	get user() {
		return this.instantUser;
	}

	get user$(): Observable<ApplicationUser> {
		return this._user.asObservable();
	}

	abstract getUser(): Observable<ApplicationUser>;
	load() {
		return this.getUser();
	}

}
