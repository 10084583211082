import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {mustMatch, NotMatchStateMatcher} from '@tante-tobi-web/shared';

@Component({
  selector: 'partial-change-password',
  templateUrl: './change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'flex-auto'}
})
export class PartialChangePasswordComponent {
  @Output() onFormSubmit: EventEmitter<FormGroup> = new EventEmitter();

  changePasswordForm!: FormGroup;
  confirmPasswordMatcher = new NotMatchStateMatcher('newPassword', 'confirmPassword');

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {
    this.changePasswordForm = this.buildChangePasswordForm();
  }

  get isFormValid(): boolean {
    return this.changePasswordForm.invalid || this.changePasswordForm.disabled;
  }

  changePassword(): void {
    this.onFormSubmit.emit(this.changePasswordForm)
  }


  private buildChangePasswordForm(): FormGroup {
    return this._formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(|=\D*\d)(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(|=\D*\d)(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(|=\D*\d)(?=[^A-Z]*[A-Z]).{8,30}$/)]],
    }, {validators: mustMatch('newPassword', 'confirmPassword')});
  }
}
