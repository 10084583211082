  <ng-container *transloco="let t"> 
<form [formGroup]="bookingsDetailsForm">

  <!--  Instructor's Details-->
  <ng-container *ngIf="bookingDetails?.instructorDetails">
  <div class="mt-20 mb-4 font-bold text-base text-teal-500">{{ t('BOOKING.INSTRUCTOR_INFORMATION') }} </div>
 <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.ID') }}</mat-label>
      <input id="instructorId" matInput formControlName="instructorId"/>
    </mat-form-field>
     <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.FIRST_NAME') }}</mat-label>
      <input id="firstName" matInput formControlName="firstName"/>
    </mat-form-field>
 </div>

   <div class="flex gap-4 w-full">
     <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.LAST_NAME') }}</mat-label>
      <input id="lastName" matInput formControlName="lastName"/>
    </mat-form-field>
     <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.EMAIL') }}</mat-label>
      <input id="instructorEmail" matInput formControlName="instructorEmail"/>
    </mat-form-field>
  </div>
   <div class="flex gap-4 w-full">
     <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.PROFESSION') }}</mat-label>
      <input id="profession" matInput formControlName="profession"/>
    </mat-form-field>

   </div>
  </ng-container>
  <!--  Client's Details-->

  <div class="mt-20 mb-4 font-bold text-base text-teal-500">{{ t('BOOKING.CLIENT_INFORMATION') }} </div>
  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.ID') }}</mat-label>
      <input id="clientId" matInput formControlName="clientId"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.CLIENT_NAME') }}</mat-label>
      <input id="fullName" matInput formControlName="fullName"/>
    </mat-form-field>
  </div>

  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.CLIENT_EMAIL') }}</mat-label>
      <input id="email" matInput formControlName="email"/>
    </mat-form-field>
      <mat-form-field class="w-full" *ngIf="bookingDetails?.clientDetails?.organizationName">
      <mat-label>{{ t('BOOKING.ORGANIZATION_NAME') }}</mat-label>
      <input id="organizationName" matInput formControlName="organizationName"/>
    </mat-form-field>
  </div>


  <!--  Booking Details-->
  <div class="mt-20 mb-4 font-bold text-base text-teal-500">{{ t('BOOKING.BOOKING_DETAILS') }}</div>
  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.BOOKING_ID') }}</mat-label>
      <input id="bookingId" matInput formControlName="bookingId"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.BOOKING_STATUS') }}</mat-label>
      <input id="status" matInput formControlName="status"/>
    </mat-form-field>
  </div>


  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.SCHEDUAL_START_DATE') }}</mat-label>
      <input id="scheduleDate" matInput formControlName="scheduleDate"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.SCHEDUAL_END_DATE') }}</mat-label>
      <input id="scheduleEndDate" matInput formControlName="scheduleEndDate"/>
    </mat-form-field>
  </div>

  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.DURATION') }} ({{ t('BOOKING.DAYS') }})</mat-label>
      <input id="duration" matInput formControlName="duration"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.WORKSHOP_MODE') }}</mat-label>
      <input id="workshopMode" matInput formControlName="workshopMode"/>
    </mat-form-field>
  </div>

  <mat-form-field class="w-full">
    <mat-label>{{ t('BOOKING.CREATION_DATE') }}</mat-label>
    <input id="creationDate" matInput formControlName="creationDate"/>
  </mat-form-field>


  <!--  Workshop Details-->
  <div class="mt-20 mb-4 font-bold text-base text-teal-500">{{ t('BOOKING.WORKSHOP_DETAILS') }}</div>

  <mat-form-field class="w-full">
    <mat-label>{{ t('BOOKING.WORKSHOP_NAME') }}</mat-label>
    <input id="workshopName" matInput formControlName="workshopName"/>
  </mat-form-field>

  <div class="flex gap-4 w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.CATEGORY') }}</mat-label>
      <input id="categoryName" matInput formControlName="categoryName"/>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ t('BOOKING.SUB_CATEGORY') }}</mat-label>
      <input id="subCategoryName" matInput formControlName="subCategoryName"/>
    </mat-form-field>
  </div>

  <mat-form-field class="w-full">
    <mat-label>{{ t('BOOKING.TOPIC') }}</mat-label>
    <input id="topicName" matInput formControlName="topicName"/>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label>{{ t('BOOKING.SUMMARY') }}</mat-label>
    <textarea id="summary" matInput rows="6" formControlName="summary"></textarea>
  </mat-form-field>

</form>

<ng-container *ngIf="showAgenda">
<div class="mt-20 mb-4 font-bold text-base text-teal-500">{{ t('BOOKING.ABOUT') }}</div>
<mat-stepper class="mt-8" orientation="vertical" [linear]="true">
  <mat-step
    completed
    *ngFor="let item of bookingDetails.workshopDetails?.agenda; let index = index"
  >
    <ng-template matStepLabel> {{ item.title }} </ng-template>

    <div class="mt-4">
      <div class="space-y-4">
        <div class="flex">
          <mat-form-field disabled class="flex-1">
            <textarea
              matInput
              disabled
              [spellcheck]="false"
              rows="6"
              [value]="item.description"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-step>
</mat-stepper>
</ng-container>

<div class="mb-15"></div>
  </ng-container>
