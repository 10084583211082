import { map } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter} from '@angular/core';
import {assertNotNullOrUndefined} from "@tante-tobi-web/utils";
import {Column} from "@tante-tobi-web/partials";

type Data = Record<string, any>[];

@Component({
  selector: 'partial-basic-table',
  templateUrl: './basic-table.component.html',
  host: {
    class:
      'shadow block rounded-lg overflow-auto border-2 border-neutral-200 border-solid',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicTableComponent implements OnInit {

  @Input() public displayColumns: Column[] = [];
  @Input() public data!: Data;
  @Output() refreshTable = new EventEmitter<void>();
  
  get displayedColumns() {
    return this.displayColumns.map((it) => it.key);
  }
 
  ngOnInit(): void {
    assertNotNullOrUndefined(this.data, 'BasicTableComponent.data');
  }
}
