<!-- Item wrapper -->
<div
  class="partial-vertical-navigation-item-wrapper"
  [class.partial-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="$any(item.classes)?.wrapper"
>
  <div class="partial-vertical-navigation-item">
    <!-- Icon -->
    <ng-container *ngIf="item.icon">
      <mat-icon
        class="partial-vertical-navigation-item-icon"
        [ngClass]="$any(item.classes)?.icon"
        >{{ item.icon }}</mat-icon
      >
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="partial-vertical-navigation-item-title-wrapper">
      <div class="partial-vertical-navigation-item-title">
        <span [ngClass]="$any(item.classes)?.title">
           {{ item.title | transloco }}
        </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="partial-vertical-navigation-item-subtitle">
          <span [ngClass]="$any(item.classes)?.subtitle">
            {{ item.subtitle }}
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="partial-vertical-navigation-item-badge">
        <div
          class="partial-vertical-navigation-item-badge-content"
          [ngClass]="$any(item.badge).classes"
        >
          {{ item.badge.title }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngFor="let item of item.children; trackBy: trackByFn">
  <!-- Skip the hidden items -->
  <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
    <!-- Basic -->
    <ng-container *ngIf="item.type === 'basic'">
      <partial-vertical-navigation-basic-item
        [item]="item"
        [name]="name"
      ></partial-vertical-navigation-basic-item>
    </ng-container>

    <!-- Collapsable -->
    <ng-container *ngIf="item.type === 'collapsable'">
      <partial-vertical-navigation-collapsable-item
        [item]="item"
        [name]="name"
        [autoCollapse]="autoCollapse"
      ></partial-vertical-navigation-collapsable-item>
    </ng-container>

    <!-- Divider -->
    <ng-container *ngIf="item.type === 'divider'">
      <partial-vertical-navigation-divider-item
        [item]="item"
        [name]="name"
      ></partial-vertical-navigation-divider-item>
    </ng-container>

    <!-- Group -->
    <ng-container *ngIf="item.type === 'group'">
      <partial-vertical-navigation-group-item
        [item]="item"
        [name]="name"
      ></partial-vertical-navigation-group-item>
    </ng-container>

    <!-- Spacer -->
    <ng-container *ngIf="item.type === 'spacer'">
      <partial-vertical-navigation-spacer-item
        [item]="item"
        [name]="name"
      ></partial-vertical-navigation-spacer-item>
    </ng-container>
  </ng-container>
</ng-container>
