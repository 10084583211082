import {Pipe, PipeTransform} from '@angular/core';
import {AppUtils} from '@tante-tobi-web/utils';

class WrappedValue<T> {
  isPresent: boolean;

  constructor(public value: T) {
    this.isPresent = !value;
  }
}

@Pipe({
  name: 'wrap',
  pure: true,
})
export class WrapPipe implements PipeTransform {
  transform<T>(value: T | null): null | WrappedValue<T> {
    return AppUtils.isNullOrUndefined(value) ? null : new WrappedValue<T>(value);
  }
}
