import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import {DirectivesModule, MaterialModule} from '@tante-tobi-web/shared';
import {TableHeightDirective} from './table-height.directive';
import {TableSidenavContentDirective} from './table-sidenav-content.directive';
import {TableComponent} from './table.component';



@NgModule({
  declarations: [
    TableComponent,
    TableSidenavContentDirective,
    TableHeightDirective,
  ],
  exports: [TableComponent],
  imports: [CommonModule, MaterialModule, DirectivesModule, TranslocoModule],
})
export class TableModule {}
