import {HttpClient, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiConstants} from '../../constants';
import {BaseResponse, MediaModel} from '../../models';


@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  uploadMedia(file: File): Observable<HttpEvent<BaseResponse<MediaModel.MediaResponse>>> {
    const formData = new FormData();
    formData.append('file', file);
    return this._httpClient
      .configure({
        fullResponse: true,
      })
      .post<BaseResponse<MediaModel.MediaResponse>>(ApiConstants.MEDIA.uploadMedia, formData, {
        responseType: 'json',
        reportProgress: true,
        observe: 'events',
      });
  }
}
