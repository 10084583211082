<mat-stepper
  orientation="vertical"
  [linear]="true"
  #stepper
  [disableRipple]="true"
>
  <ng-container *ngFor="let step of steps; let i=index">
    <mat-step [stepControl]="step" [label]="step.getControlValue(label)">
      <ngx-form-factory
        class="pt-4"
        [formGroup]="step"
        [submitButtonOptions]="{ show: false }"
      >
      </ngx-form-factory>
      <button mat-button matStepperPrevious *ngIf="i !== 0 "> {{'BACK' | transloco}} </button>
      <button mat-button matStepperNext  *ngIf="i !== steps.length-1" [disabled]="!step.valid">{{'NEXT' | transloco}}</button>
    </mat-step>
  </ng-container>
</mat-stepper>
