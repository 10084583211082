import {CategoryModel} from "./category.model";
import {MediaModel} from "./media.model";

export namespace WorkshopModel {

  import SubCategory = CategoryModel.SubCategory;

  export interface Agenda {
    id: number;
    title: string;
    description: string;
    workshopId: number;
    isEnabled: boolean;
  }

  export interface Category {
    name: string;
    id: number;
  }

  export interface Topic {
    name: string;
    id: number;
  }


  export interface Workshop {
    id: number;
    isEnabled: boolean;
    workshopName: string;
    duration: number;
    topic: Topic;
    category: Category;
    level: string;
    summary: string;
    description: string;
    maxAttendance: number;
    teachingMethod: string[];
    originPrice: number;
    basePrice?: number;
    coverImageId: number;
    coverImageUrl: string;
    bannerImageId: number;
    bannerImageUrl: string;
    isPublished?: boolean;
    isEngaged: boolean;
    creationDate: Date;
    agenda: Agenda[];
    crossWorkshops: Workshop[];
    categoryId?: number;
    subCategoryId?: number;
    topicId?: number;
    notes?: string;
    suggestedPrice?: number;
    createdByUserId?: number;
    instructorId?: number;
    instructorFirstName?: string;
    instructorLastName?: string;
    instructorEmail?: string;
    subCategory?: SubCategory;
    generateCertificate?: boolean;
  }
 export interface WorkshopEngagement {
   id: number;
   creationDate: Date;
   isEngaged?: boolean;
   status: string;
   instructorResponse: InstructorRespons;
   workshopResponse: WorkshopResponse;
   instructorLastName: string;
   workshopName: string;
   workshopId: number;
   instructorId: number;
   topic: string;
   isPublished: boolean;
   documents: WorkshopMedia[];
   isOnline: boolean;
   isOnsite: boolean;
   topicId?: number;
 }
 export interface InstructorRespons {
   id: number;
   userId: number;
   imageId: number;
   imageUrl: string;
   firstName: string;
   lastName: string;
   workplace: string;
   email: string;
   phoneNumber: string;
   username: string;
   taxNumber: string;
   addressLine: string;
   bio: string;
   bankAccountNumber: string;
   bankName: string;
   branchName: number;
   swiftCode: string;
   profession: string;
   yearsOfExperience: number;
   skills: string[];
   languages: string[];
   linkedinUrl: string;
   rating: number;
 }
 export interface WorkshopResponse {
   id: number;
   isEnabled: boolean;
   isEngaged: boolean;
   status: string;
   workshopName: string;
   duration: number;
   category: Category;
   subCategory: SubCategory;
   topic: Topic;
   level: string;
   summary: string;
   description: string;
   maxAttendance: number;
   isOnline: boolean;
   isOnsite: boolean;
   originPrice: number;
   basePrice: string;
   coverImageId: number;
   coverImageUrl: string;
   bannerImageId: number;
   bannerImageUrl: string;
   isPublished: boolean;
   creationDate: Date;
   agenda: Agenda[];
   crossWorkshops: string[];
 }

  export interface SuggestWorkshopAgenda {
    title: string;
    description: string;
  }

  export interface SuggestWorkshop {
    createdByUserId: string;
    instructorId: string;
    workshopName: string;
    duration: number;
    categoryId?: number;
    categoryName?: string | Category;
    subCategoryId?: number;
    subCategoryName?: string | SubCategory;
    topicId?: number;
    topicName?: string | Topic;
    level: string;
    summary: string;
    description: string;
    maxAttendance: number;
    teachingMethod?: string;
    notes: string;
    suggestedPrice: number;
    isOnsite: boolean;
    isOnline: boolean;
    agenda?: SuggestWorkshopAgenda[];
  }

  export interface EngageWorkshopRequest {
    workshopId: number;
    documents: Array<MediaModel.DocumentsRequest>;
    teachingMethod: string[];
  }

  export interface WithdrawWorkshopRequest {
    workshopId: number;
    reason?: string;
  }
  export interface WorkshopMedia {
    creationDate: Date;
    id: number;
    instructorId: number;
    workshopId: number;
    mediaId: number;
    mediaUrl: string;
    documentName: string;
  }
  export interface UploadWorkshopMedia {
    documents: Array<MediaModel.DocumentsRequest>;
    workshopId: number;
    instructorId: number;
  }

}

