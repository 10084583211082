import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmPopup } from '../Iconfirm';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent {
  result?: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmPopup,
    public dialogRef: MatDialogRef<ConfirmComponent>,
  ) { }

  onNoClick(value: boolean): void {
    this.result = value;
    this.dialogRef.close(value);
  }

}
