import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';
import {NotificationItemComponent} from "./notification-item/notification-item.component";
import {NotificationToastComponent} from "./notification-toast/notification-toast.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {DirectivesModule} from "@tante-tobi-web/shared";
import {MatButtonModule} from "@angular/material/button";
import {NotificationsMenuComponent} from "./notifications-menu.component";


@NgModule({
  declarations: [
    NotificationsMenuComponent,
    NotificationItemComponent,
    NotificationToastComponent
  ],
  exports: [
    NotificationsMenuComponent,
    NotificationItemComponent,
    NotificationToastComponent
  ],
  imports: [CommonModule, TranslocoModule, MatMenuModule, MatDividerModule, MatIconModule, DirectivesModule, MatButtonModule],
})
export class NotificationsMenuModule {
}
