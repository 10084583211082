import {AppUtils} from '@tante-tobi-web/utils';
import {createValidator} from './validator_factory';

export const ContainsUppercase = () => createValidator('uppercase', control => /[A-Z]/.test(control.value ?? ''));
export const ContainsLowercase = () => createValidator('lowercase', control => /[a-z]/.test(control.value ?? ''));
export const ContainsNumber = () => createValidator('number', control => /\d/.test(control.value));
export const ContainsSpecialCharacter = () => createValidator('special_character', control => /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value));
export const Contains = (value: string, times?: number) => createValidator('contains', (control) => {
    const result = control.value?.match(new RegExp(value, 'g'));
    if (AppUtils.notNullOrUndefined(result)) {
        if (AppUtils.notNullOrUndefined(times)) {
            return result.length === times;
        }
        return !!result.length;
    }
    return false;
});

export const Between = (minlength: number, maxlength: number) => createValidator('between', control => length(control.value) > minlength - 1 && length(control.value) < maxlength - 1);

const length = (value: string) => value && value.length;

export const AllEqual = (...names: string[]) => createValidator('equality', (control) => {
    const values = names.map(name => control.get(name)?.value);
    for (let i = 1; i < values.length; i++) {
        if (values[i] !== values[0]) {
            return false;
        }
    }
    return true;
});

export const EqualTo = (name: string) => createValidator('equalTo', (control, parent) => parent?.get(name)?.value?.trim() === control.value?.trim());
