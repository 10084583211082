import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {RequestOptionsModule} from '@ezzabuzaid/ngx-request-options';
import {
  LoggerInterceptor,
  ProgressInterceptor, TeardownInterceptor, UrlInterceptor
} from './interceptors';
import {SetupInterceptor} from './interceptors/setup/setup.interceptor';
import {IRequestOptions} from './request-options';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RequestOptionsModule.forRoot<IRequestOptions>({
      snackbar: null,
      progressBar: true,
      fullResponse: false,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetupInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TeardownInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
