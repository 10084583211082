export namespace ApiConstants {
  export const AUTH = {
    base: 'auth/',
    get refresh() {
      return this.base + 'refresh';
    },
    get login() {
      return this.base + 'login';
    },
    get logout() {
      return this.base + 'logout';
    },
    get forgetPassword() {
      return this.base + 'forget-password';
    }
  };
  export const PROFILE = {
    base: 'instructor/',
    get viewProfile() {
      return this.base + 'profile/view-profile';
    },
    get changePassword() {
      return this.base + 'profile/change-old-password';
    },
    get profile() {
      return this.base + 'profile';
    }
  };
  export const ADMIN_PROFILE = {
    base: 'admin/',
    get viewProfile() {
      return this.base + 'profile/view-profile';
    },
    get changePassword() {
      return this.base + 'profile/change-old-password';
    }
  };
  export const WORKSHOPS = {
    base: 'instructor/workshops/',
    get workshops() {
      return this.base;
    },
    get filterWorkshops() {
      return this.base;
    },
    get engage() {
      return this.base + 'engage';
    },
    get withdraw() {
      return this.base + 'withdraw';
    },
    get engaged() {
      return this.base + 'engaged';
    },
    get suggestWorkshop() {
      return this.base + '';
    }
  };
  export const SUGGESTIONS = {
    base: 'instructor/workshops-suggestions',
    get suggestWorkshop() {
      return this.base;
    }
  };
  export const CATEGORIES = {
    base: 'instructor/',
    get getCategories() {
      return this.base + 'categories';
    },
    get getCategorySubCategories() {
      return this.base + 'sub-categories/category';
    },
    get getSubcategoryTopics() {
      return this.base + 'topics/sub-category';
    },
  };

  export const MEDIA = {
    base: 'media',
    get uploadMedia() {
      return this.base + '/upload';
    }
  };

  export const CALENDER = {
    base: 'instructor/calendar/slots',
    get slots() {
      return this.base;
    },
    get addUnavailabilityPeriod() {
      return this.base;
    }
  };
  export const STATEMENTS = {
    base:'instructor/bookings-statements',
    get statements(){
      return this.base;
    }
  }
  export const STATEMENTS_ADMIN = {
    base: 'admin/bookings-statements',
    get statements() {
      return this.base;
    },
    get export(){
      return this.base + '/export';
    }
  }
  export const CATEGORIES_ADMIN = {
    base: 'admin/',
    get getCategories() {
      return this.base + 'categories';
    },
  }
  export const ADMIN_WORKSHOPS = {
    base: 'admin/instructors-workshops',
    get media(){
      return this.base + '/media';
    }
  };
  export const ADMIN_STATISTICS = {
    base: 'admin/statistics',
    get statistics(){
      return this.base
    }
  };

  export const INSTRUCTOR_STATISTICS = {
    base: 'instructor/statistics',
    get statistics(){
      return this.base
  }}
}
