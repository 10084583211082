<div class="flex justify-between">
  <p mat-dialog-title>{{ data?.title || "confirmition" }}</p>
  <button class="relative bottom-px" mat-icon-button (click)="onNoClick(false)">
    <mat-icon>close</mat-icon>
  </button>
</div>
<p mat-dialog-content>{{ data?.description }}</p>
<div mat-dialog-actions align="end" class="mt-4">
  <button
    mat-flat-button
    color="primary"
    (click)="onNoClick(true)"
    cdkFocusInitial
  >
    {{ data?.confirm || "Confirm" }}
  </button>
  <button *ngIf="data.close" mat-flat-button (click)="onNoClick(false)">
    {{ data.close }}
  </button>
</div>
