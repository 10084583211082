import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE} from '@ng-web-apis/common';

export type Theme = 'dark' | 'light';
const THEME_NAME_KEY = 'theme';
@Injectable({
    providedIn: 'root'
})
export class ThemeState {

    constructor(
        @Inject(LOCAL_STORAGE) private _localStorage: Storage,
        @Inject(DOCUMENT) private _document: Document,
    ) { }

    get currentTheme() {
        const systemTheme: Theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        return this._localStorage.getItem(THEME_NAME_KEY) as Theme ?? systemTheme;
    }

    activateTheme(theme: Theme = this.currentTheme) {
        this.changeTheme(theme);
    }

    changeTheme(themeName: Theme) {
        this._document.body.classList
            .forEach((className) => {
                if (className.startsWith('dark') || className.startsWith('light')) {
                    this._document.body.classList.remove(className);
                }
            });
        this._localStorage.setItem(THEME_NAME_KEY, themeName);
        this._document.body.classList.add(themeName);
    }

    toggleTheme() {
        this.changeTheme(this.currentTheme === 'dark' ? 'light' : 'dark');
    }

}
