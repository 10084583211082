export namespace RoutesConstants {
  export const CALENDER = {
    withSlash: '/calender',
    withoutSlash: 'calender',
  };
  export const WORKSHOPS = {
    withSlash: '/workshops',
    withoutSlash: 'workshops',
  };
  export const ENGAGED = {
    withSlash: `${WORKSHOPS.withSlash}/engaged`,
    withoutSlash: 'engaged',
  };
  export const DISCOVER = {
    withSlash: `${WORKSHOPS.withSlash}/discover`,
    withoutSlash: 'discover',
  };
  export const BOOKINGS = {
    withSlash: `/bookings`,
    withoutSlash: 'bookings',
  };
  export const AUTH = {
    withSlash: '/auth',
    withoutSlash: 'auth',
  };
  export const LOGIN = {
    withSlash: `${AUTH.withSlash}/login`,
    withoutSlash: 'login'
  };
  export const PROFILE = {
    withSlash: '/profile',
    withoutSlash: 'profile'
  };
  export const NOT_FOUND = {
    withSlash: '/404',
    withoutSlash: '404'
  };
  export const DASHBOARD = {
    withSlash: '/',
    withoutSlash: ''
  };
  export const PERSONAL_INFO = {
    withSlash: '/personal-info',
    withoutSlash: 'personal-info'
  };
  export const TECHNICAL_INFO = {
    withSlash: '/technical-info',
    withoutSlash: 'technical-info'
  };
  export const CHANGE_PASSWORD = {
    withSlash: '/change-password',
    withoutSlash: 'change-password'
  };
  export const STATEMENTS_LIST = {
    withSlash: '/statements',
    withoutSlash: 'statements'
  };
  export const DEFAULT = RoutesConstants.DASHBOARD;

}
