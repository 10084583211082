import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Debug/Log RxJS event emission
 *
 * @param tag log prefix
 * @returns
 */
export const debug = <T>(tag: string | ((value: T | null, error: object | null) => string)): MonoTypeOperatorFunction<T> => {
    const header = (value: T | null, error: object | null) => typeof tag === 'string' ? tag : tag(value, error);
    return tap<T>({
        next: (value) => {
            console.log(`%c[${header(value, null)}: Next]`, 'background: #009688; color: #fff; padding: 3px; font-size: 9px;', value);
        },
        error: (error) => {
            console.log(`%[${header(null, error)}: Error]`, 'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;', error);
        },
        complete: () => {
            console.log(`%c[${header(null, null)}]: Complete`, 'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;');
        }
    });
};
