import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookingModel, CalendarModel, InstructorUser, PaginationQuery, PaginationResult } from '@tante-tobi-web/infrastructure';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private _httpClient: HttpClient) {}

  getBookingsList(query: PaginationQuery): Observable<PaginationResult<BookingModel.Booking[]>> {
    return this._httpClient
      .configure({ fullResponse: true })
      .get<PaginationResult<BookingModel.Booking[]>>(`admin/bookings?${query.asString()}`);
  }

  getBookingById(id: number): Observable<BookingModel.Booking> {
    return this._httpClient.get<BookingModel.Booking>(`admin/bookings/${id}`);
  }

  getStatementInvoice(id: number): Observable<BookingModel.IStatementInvoice> {
    const params = new HttpParams().set('statementId', id.toString());
    return this._httpClient.get<BookingModel.IStatementInvoice>(`admin/bookings-statements/invoice`, { params });
  }

  getAvailableInstructors(query: PaginationQuery): Observable<InstructorUser[]> {
    return this._httpClient.get<InstructorUser[]>(`admin/bookings/available-instructors?${query.asString()}`);
  }

  getTimeSlots(bookingId: number, date: string): Observable<CalendarModel.BookingSlot[]> {
    return this._httpClient.get<CalendarModel.BookingSlot[]>(`admin/bookings/reschedule-slots/${bookingId}?date=${date}`);
  }

  // @ts-ignore
  rescheduleBooking({ bookingId, scheduleDate }): Observable<any> {
    return this._httpClient.patch<BookingModel.IRescheduleResponse>('admin/bookings/reschedule', {
      bookingId,
      scheduleDate,
    });
  }

  cancelBooking(bookingId: number): Observable<any> {
    return this._httpClient.patch<any>(`admin/bookings/cancel/${bookingId}`, {});
  }

  // @ts-ignore
  reassignInstructor({ bookingId, newInstructorId }): Observable<string> {
    return this._httpClient
      .patch<BookingModel.Booking>('admin/bookings/reassign-instructor', {
        bookingId,
        newInstructorId,
      })
      .pipe(
        map((res) => res.instructorDetails),
        map((instructorDetails) => `${instructorDetails.firstName}  ${instructorDetails.lastName}`)
      );
  }

  issueCertificate(issueCertificate: BookingModel.IIssueCertificate): Observable<any> {
    return this._httpClient
      .configure({snackbar:{successMessage:'SUCCESS.SUCCESS', errorMessage:'ERRORS.SOMETHING_WENT_WRONG'}})
      .post<any>(`admin/booking-members/issue-certificate`, issueCertificate);
  }

  getReviews() {
    return this._httpClient.get<BookingModel.Reviews[]>(`admin/reviews`);
  }
  getInstructorReviews(id: number) {
    return this._httpClient.get<BookingModel.Reviews[]>(`admin/reviews?instructorId=${id}`);
  }

  updateReview(id: number, review: Partial<BookingModel.Reviews>) {
    return this._httpClient
      .configure({snackbar:{successMessage:'SUCCESS.SUCCESS', errorMessage:'ERRORS.SOMETHING_WENT_WRONG'}})
      .put<BookingModel.Reviews>(`admin/reviews/${id}`, review);
  }

  deleteReview(id: number) {
    return this._httpClient
      .configure({snackbar:{successMessage:'SUCCESS.SUCCESS', errorMessage:'ERRORS.SOMETHING_WENT_WRONG'}})
      .delete<BookingModel.Reviews>(`admin/reviews/${id}`);
  }
}
