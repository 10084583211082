import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import {FormFactoryModule} from '@ezzabuzaid/ngx-form-factory';
import {StepperFieldComponent} from './stepper-field.component';
import {MatButtonModule} from "@angular/material/button";
import {TranslocoModule} from "@ngneat/transloco";



@NgModule({
    declarations: [
        StepperFieldComponent
    ],
  imports: [
    CommonModule,
    MatStepperModule,
    FormFactoryModule,
    MatButtonModule,
    TranslocoModule
  ]
})
export class StepperFieldModule { }
