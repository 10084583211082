import {Component, Inject} from '@angular/core';
import {TRANSLOCO_LOADER, TranslocoService} from '@ngneat/transloco';
import {TranslocoPersistTranslations} from '@ngneat/transloco-persist-translations';
import {ThemeState} from '@tante-tobi-web/infrastructure';
import {filter, switchMap} from 'rxjs';
import {environment} from "../environments/environment";
import {AuthService, UserService} from "./services";
@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'flex flex-auto w-full h-full'
  },
  styles: [],
})
export class AppComponent {

  constructor(
    private _themeState: ThemeState,
    private _authService: AuthService,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    @Inject(TRANSLOCO_LOADER) private _loader: TranslocoPersistTranslations
  ) {
    this.selectLanguage()
    this._themeState.activateTheme('light');

    this._authService.authenticated$
      .pipe(
        filter(auth => auth),
        switchMap(() => this._userService.load())
      )
      .subscribe();
  }

  // FIXME: maintain angular version and remove the translation on deploy (version change).
  // FIXME: https://ngneat.github.io/transloco/docs/blog-posts
  clearTranslationsCache() {
    this._loader.clearCache();
  }

  selectLanguage() {
    switch (environment.lang) {
      case 'de':
        this._translocoService.setActiveLang('de');
        break;
      case 'en':
        this._translocoService.setActiveLang('en');
        break;
      default:
        this._translocoService.setActiveLang('de');
        break;
    }
  }

}
