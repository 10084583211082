
export namespace VoucherModel {
  export interface Voucher {
    id: number;
    voucherCode: string;
    discountPercentage: number;
    maxDiscountAmount: number;
    startDate: string;
    endDate: string;
    categoryId: number[];
    maxUsage: number;
    maxUsagePerUser: number;
    isEnabled: boolean;
  }
}
