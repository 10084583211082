import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[addClass]'
})
export class AddClassDirective implements AfterViewInit {
    @Input('addClass') classes!: string[];
    @Input('event') public eventName!: string;

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef<HTMLElement>
    ) {
    }
    ngAfterViewInit(): void {
        const el = this.elementRef.nativeElement;
        this.renderer.listen(document, 'click', (event) => {
            if (event.target !== el)
                {this.classes.forEach((cssClass) => {
                    this.renderer.removeClass(el, cssClass);
                });}
        });
        this.renderer.listen(el, this.eventName, () => {
            this.classes.forEach((cssClass) => {
                this.renderer.addClass(el, cssClass);
            });
        });

    }
}
