<table mat-table [dataSource]="data" class="w-full h-full">
  <ng-container *ngFor="let column of displayColumns">
    <ng-container [matColumnDef]="column.key">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="text-black px-4 {{column.class}}"
        [ngStyle]="{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width
            }"
      >
        {{ column.title }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="px-4 {{column.class}}"
        [ngStyle]="{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width
            }"
      >
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'component'">
            <ng-container
              [dynamic-component]="column.component!"
              [inputs]="{ row: element, cell: element[column.key] }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'valueFormatter'">
            {{ column.valueFormatter!(element, element[column.key]) }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ element[column.key] }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
    class="bg-neutral-200 h-13"
  ></tr>
  <tr
    class="h-13"
    mat-row
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
  <tr *matNoDataRow>
    <td [attr.colspan]="displayedColumns.length" class="w-full h-full">
      <div class="flex flex-col items-center justify-center font-bold text-neutral-300 text-xl my-4">
        <mat-icon class="text-9xl w-20 h-20 text-neutral-300">search_off</mat-icon>
        No Data Found
      </div>
    </td>
  </tr>
</table>
