import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPromptPopup } from '../Iprompt';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
})
export class PromptComponent {
  constructor(
    private readonly ref: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPromptPopup
  ) { }

  close() {
    this.ref.close(this.data.value?.trim());
  }

}
