import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {BookingModel} from "@tante-tobi-web/infrastructure";
import {FormBuilder, FormGroup} from "@angular/forms";
import moment from "moment";
import {startCase} from "lodash-es";

@Component({
  selector: 'partial-general-info',
  templateUrl: './general-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInfoComponent implements OnInit {
  bookingsDetailsForm: FormGroup
  @Input('row') bookingDetails!: BookingModel.Booking
  @Input() showAgenda = true

  constructor(private _fb: FormBuilder) {
    this.bookingsDetailsForm = this.buildPaymentDetailsForm()
  }

  ngOnInit(): void {
    const instructorDetails = this.bookingDetails?.instructorDetails;
    const instructorEmail = this.bookingDetails?.instructorDetails?.email;
    const instructorId = this.bookingDetails?.instructorDetails?.id;
    const clientDetails = this.bookingDetails.clientDetails
    const bookingDetails = this.bookingDetails
    const bookingId = this.bookingDetails.id
    const clientId = this.bookingDetails.clientDetails.id
    const creationDate = moment(this.bookingDetails.creationDate).format('d/mm/yyyy hh:mm A')
    const workshopDetails = this.bookingDetails.workshopDetails
    const categoryName = workshopDetails.category?.name
    const subCategoryName = workshopDetails.subCategory?.name
    const topicName = workshopDetails.topic?.name
    const status = startCase(this.bookingDetails.status?.toLowerCase())
    this.bookingsDetailsForm.patchValue({
      ...bookingDetails,
      ...instructorDetails,
      ...clientDetails,
      ...workshopDetails,
      instructorEmail,
      instructorId,
      bookingId,
      clientId,
      creationDate,
      categoryName,
      subCategoryName,
      topicName,
      status,
    });
    this.bookingsDetailsForm.disable()
  
  }

  buildPaymentDetailsForm() {
    return this._fb.group({
      profession: [''],
      instructorEmail: [''],
      instructorId: [''],
      firstName: [''],
      lastName: [''],
      bookingId: [''],
      clientId: [''],
      fullName: [''],
      email: [''],
      organizationName: [''],
      creationDate: [''],
      duration: [''],
      scheduleDate: [''],
      scheduleEndDate: [''],
      status: [''],
      workshopMode: [''],
      workshopName: [''],
      categoryName: [''],
      subCategoryName: [''],
      topicName: [''],
      summary: [''],
    });
  }

}
