import {AfterViewInit, Directive, ElementRef, HostListener, Inject, OnDestroy, Renderer2} from '@angular/core';
import {WINDOW} from '@ng-web-apis/common';
import {AppUtils, createResizeObservable} from '@tante-tobi-web/utils';
import {Subject, takeUntil} from 'rxjs';

@Directive({
	selector: '[fit-content]'
})
export class FitContentDirective implements AfterViewInit, OnDestroy {
	private subscription = new Subject();

	constructor(
		private _elementRef: ElementRef<HTMLElement>,
		private _renderer: Renderer2,
		@Inject(WINDOW) private _window: Window
	) { }

	ngOnDestroy(): void {
		AppUtils.unsubscribe(this.subscription);
	}

	ngAfterViewInit(): void {
		createResizeObservable(this.element)
			.pipe(
				takeUntil(this.subscription)
			)
			.subscribe(() => {
				this._setHeight();
				console.log(this._setHeight());
			});
	}

	get element() {
		return this._elementRef.nativeElement;
	}

	private _calculateHeight() {
		const elementEdges = this._edges(this.element);
		const bottomAreaHeight = (document.body.scrollHeight - window.innerHeight);
		return this._fullHeight(this.element) - bottomAreaHeight - elementEdges;
	}

	_fullHeight(element: Element | null) {
		if (element) {
			return this._window.innerHeight - element.getBoundingClientRect().top;
		}
		return 0;
	}

	private _edges(node: Element | null, ...moreEdges: string[]) {
		if (!node) {
			return 0;
		}
		const list = [
			'margin-top',
			// 'border-top',
			'padding-top',
			'margin-bottom',
			// 'border-bottom',
			'padding-bottom',
			...moreEdges
		];

		const style = this._window.getComputedStyle(node);
		return list
			.map(k => parseInt(style.getPropertyValue(k), 10))
			.reduce((prev, cur) => prev + cur);
	}

	private _setHeight() {
		this._renderer.setStyle(this.element, 'height', `${this._calculateHeight()}px`);
		this._renderer.setStyle(this.element, 'max-height', `${this._calculateHeight()}px`);
	}

	@HostListener('window:resize')
	private _onWindowResize() {
		this._setHeight();
	}

}
