import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MembersComponent} from "./members.component";
import {BasicTableModule} from "@tante-tobi-web/partials";
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [MembersComponent],
  exports: [MembersComponent],
  imports: [CommonModule, BasicTableModule, TranslocoModule],
})
export class MembersModule {}
