import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationModel} from "@tante-tobi-web/infrastructure";

@Component({
    selector: 'partial-notification-item',
    templateUrl: './notification-item.component.html'
})
export class NotificationItemComponent {
    @Input() notification!: NotificationModel.Notification;
    @Output() handleClick = new EventEmitter<NotificationModel.Notification>();


    constructor(private _router: Router) {
    }

}
