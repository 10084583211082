import {Component} from "@angular/core";
import {ProgressBarState} from "@tante-tobi-web/infrastructure";
import navigation from "../navigation";
import {UserService} from "../services";

@Component({
	selector: 'admin-container',
	templateUrl: './container.component.html',
	styles: [`
	:host {
		display: flex;
		flex: 1 1 auto;
		width: 100%;
		max-width: 100%;
		min-width: 0;

		/* Base styles for individual layouts */
		> * {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			width: 100%;
		}

		/* Base styles for components that load as a route */
		router-outlet {
			+ * {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			width: 100%;
			height: 100%;
			}
		}
	}
`]
})
export class ContainerComponent {
	public visible$ = this._progressBarState.onVisibilityChange();
	public navigation = navigation;
	user$ = this._userService.user$;
	constructor(
		private _userService: UserService,
		private _progressBarState: ProgressBarState,
	) { }
}
