<!-- Item wrapper -->
<div
  class="partial-vertical-navigation-item-wrapper"
  [class.partial-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="$any(item.classes)?.wrapper"
>
  <!-- Item with an internal link -->
  <ng-container
    *ngIf="item.link && !item.externalLink && !item.function && !item.disabled"
  >
    <a
      class="partial-vertical-navigation-item"
      [ngClass]="{
        'partial-vertical-navigation-item-active-forced': item.active
      }"
      [routerLink]="[item.link]"
      [routerLinkActive]="'partial-vertical-navigation-item-active'"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [matTooltip]="item.tooltip || ''"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with an external link -->
  <ng-container
    *ngIf="item.link && item.externalLink && !item.function && !item.disabled"
  >
    <a
      class="partial-vertical-navigation-item"
      [href]="item.link"
      [target]="item.target || '_self'"
      [matTooltip]="item.tooltip || ''"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with a function -->
  <ng-container *ngIf="!item.link && item.function && !item.disabled">
    <div
      class="partial-vertical-navigation-item"
      [ngClass]="{
        'partial-vertical-navigation-item-active-forced': item.active
      }"
      [matTooltip]="item.tooltip || ''"
      (click)="item.function(item)"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- Item with an internal link and function -->
  <ng-container
    *ngIf="item.link && !item.externalLink && item.function && !item.disabled"
  >
    <a
      class="partial-vertical-navigation-item"
      [ngClass]="{
        'partial-vertical-navigation-item-active-forced': item.active
      }"
      [routerLink]="[item.link]"
      [routerLinkActive]="'partial-vertical-navigation-item-active'"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [matTooltip]="item.tooltip || ''"
      (click)="item.function(item)"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with an external link and function -->
  <ng-container
    *ngIf="item.link && item.externalLink && item.function && !item.disabled"
  >
    <a
      class="partial-vertical-navigation-item"
      [href]="item.link"
      [target]="item.target || '_self'"
      [matTooltip]="item.tooltip || ''"
      (click)="item.function(item)"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with a no link and no function -->
  <ng-container *ngIf="!item.link && !item.function && !item.disabled">
    <div
      class="partial-vertical-navigation-item"
      [ngClass]="{
        'partial-vertical-navigation-item-active-forced': item.active
      }"
      [matTooltip]="item.tooltip || ''"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- Item is disabled -->
  <ng-container *ngIf="item.disabled">
    <div
      class="partial-vertical-navigation-item partial-vertical-navigation-item-disabled"
      [matTooltip]="item.tooltip || ''"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<!-- Item template -->
<ng-template #itemTemplate>
  <!-- Icon -->
  <ng-container *ngIf="item.icon">
    <mat-icon
      class="partial-vertical-navigation-item-icon"
      [ngClass]="$any(item.classes)?.icon"
      >{{ item.icon }}</mat-icon
    >
  </ng-container>

  <ng-container *ngIf="item.image">
    <img class="h-5 w-5 mr-2" src="/assets/side-menu-icon/{{ item.image }}" />
  </ng-container>

  <!-- Title & Subtitle -->
  <div class="partial-vertical-navigation-item-title-wrapper">
    <div class="partial-vertical-navigation-item-title">
      <span [ngClass]="$any(item.classes)?.title" class="capitalize">
        {{ item.title | transloco }}
      </span>
    </div>
    <ng-container *ngIf="item.subtitle">
      <div class="partial-vertical-navigation-item-subtitle">
        <span [ngClass]="$any(item.classes)?.subtitle">
          {{ item.subtitle }}
        </span>
      </div>
    </ng-container>
  </div>

  <!-- Badge -->
  <ng-container *ngIf="item.badge">
    <div class="partial-vertical-navigation-item-badge">
      <div
        class="partial-vertical-navigation-item-badge-content"
        [ngClass]="$any(item.classes).classes"
      >
        {{ item.badge.title }}
      </div>
    </div>
  </ng-container>
</ng-template>
