import { AbstractControl, ValidatorFn } from '@angular/forms';

export const createValidator = (
    errorName: string,
    validator: (control: AbstractControl, parent?: AbstractControl) => boolean
): ValidatorFn => (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control) {
            return validator(control, control.parent!) ? null : { [errorName]: true };
        }
        return null;
    };
