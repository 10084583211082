import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {LumberjackModule} from '@ngworker/lumberjack';
import {LumberjackConsoleDriverModule} from '@ngworker/lumberjack/console-driver';
import {appConfig, FuseConfigModule, FuseModule} from '@tante-tobi-web/fuse';
import {
  AbstractAuthService,
  AbstractUserService,
  CoreModule,
  ENVIRONMENT
} from '@tante-tobi-web/infrastructure';
import {LayoutModule} from '@tante-tobi-web/partials';
import {ButtonModule} from '@tante-tobi-web/widgets';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ContainerComponent} from './container/container.compnent';
import {UserService} from './services';
import {TranslocoRootModule} from './transloco-root.module';
import {AuthService} from './services';

FullCalendarModule.registerPlugins([
  interactionPlugin,
  dayGridPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    ContainerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FuseModule,
    MatSnackBarModule,
    FuseConfigModule.forRoot(appConfig),
    HttpClientModule,
    TranslocoRootModule,
    CoreModule,
    LumberjackModule.forRoot(),
    LumberjackConsoleDriverModule.forRoot(),
    AppRoutingModule,
    LayoutModule,
    MatProgressBarModule,
    ButtonModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: AbstractUserService,
      useClass: UserService
    },
    {
      provide: AbstractAuthService,
      useClass: AuthService
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _library: FaIconLibrary,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
  ) {
    // Add an icon to the library for convenient access in other components
    this._library.addIconPacks(fas, far, fab);

    // Register icon sets
    this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-twotone.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-outline.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-solid.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('iconsmind', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/iconsmind.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/feather.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/heroicons-outline.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/heroicons-solid.svg'));
  }
}
