import { ClientUser, WorkshopModel } from '@tante-tobi-web/infrastructure';
import { Instructor } from '../../../../apps/admin/src/app/modules/users/user.model';

export namespace BookingModel {
  export interface JoinMeetingResponse {
    meetingUrl: string;
  }

  export interface IStatementInvoice {
    id: number;
    mediaUrl: string;
    mimeMediaType: string;
    mediaType: string;
  }

  export interface Booking {
    address?: string;
    actionable?: Actionable;
    clientDetails: ClientUser;
    creationDate: string;
    duration: number;
    id: number;
    members: Member[];
    paymentDetails: PaymentDetails;
    instructorDetails: Pick<Instructor, 'id' | 'firstName' | 'imageId' | 'lastName' | 'profession' | 'imageUrl' | 'email'>;
    scheduleDate: string;
    scheduleEndDate: string;
    status: BookingStatus;
    workshopDetails: Partial<WorkshopModel.Workshop>;
    workshopMode: 'ONLINE' | 'ON_SITE';
  }

  export type BookingStatus = 'COMPLETED' | 'ONGOING' | 'UPCOMING';

  export enum BookingStatusEnum {
    COMPLETED = 'COMPLETED',
    ONGOING = 'ONGOING',
    UPCOMING = 'UPCOMING',
  }

  export interface IIssueCertificate {
    bookingId: number;
    clientIds: IClientIds[];
  }

  export interface IClientIds {
    clientId: number;
    generateCertificate: boolean;
  }

  export interface Actionable {
    canCompleteBooking: boolean;
  }

  export interface CalenderBookingDetails {
    status: 'AVAILABLE' | 'UNAVAILABLE';
    date: string;
    bookingId?: number;
  }

  export interface PaymentDetails {
    actualAmount: number;
    vat: number;
    taxAmount: number;
    totalAmount: number;
    paymentMethod: string;
  }

  export interface Member {
    id: number;
    fullName: string;
    bookingId: number;
    email: string;
    imageId: number;
    generateCertificate?: boolean;
  }

  export interface IRescheduleResponse {
    newEndDate: string;
    newStartDate: string;
  }

  export interface Reviews {
    id: number;
    instructorId: number;
    clientId: number;
    workshopId: number;
    workshopName: string;
    bookingId: number;
    clientFullName: string;
    clientImageUrl: string;
    rating: number;
    commentDate: string;
    comment: string;
  }
}
