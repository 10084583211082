import {Observable} from 'rxjs';

/**
 * Wrapper around ResizeObserver to be used with RxJS operators
 *
 * @param element element to observe
 */
export function createResizeObservable(element: HTMLElement): Observable<ResizeObserverEntry[]> {
	return new Observable((subscriber) => {
		const resizeObserver = new ResizeObserver((entries) => {
			window.requestAnimationFrame(() => {
				// to avoid `ResizeObserver loop limit exceeded thrown` error
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				subscriber.next(entries);
			});
		});
		resizeObserver.observe(element);
		return function unsubscribe() {
			resizeObserver.unobserve(element);
		};
	});
}
