import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, iif, Observable, of, switchMap, throwError} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiConstants, RoutesConstants} from '../../constants';
import {AuthModel} from '../../models';
import {TokenManager} from './token-manager';
import {Optional} from "@angular/core";
import UserRoles = AuthModel.UserRoles;

export abstract class AbstractAuthService {

  protected constructor(
    @Optional() protected _httpClient: HttpClient,
    @Optional() protected _tokenManager: TokenManager,
    @Optional() protected _router: Router
  ) {
  }

  protected _state = new BehaviorSubject(this.authenticated);

  get authenticated() {
    return this._tokenManager.isLogged && !this._tokenManager.isExpired();
  }

  get authenticated$() {
    return this._state.asObservable();
  }

  forgotPassword(email: string): Observable<any> {
    return this._httpClient
      .configure({snackbar: null})
      .post(`${ApiConstants.AUTH.forgetPassword}?email=${email}`, {});
  }

  login(credentials: AuthModel.Login): Observable<any> {
    return this._httpClient
      .configure({snackbar: null})
      .post<AuthModel.ILoginResponse>(ApiConstants.AUTH.login, credentials)
      .pipe(
        switchMap((response) => iif(() => !this.userRoles.includes(response.roleGroup),
          throwError(() => 'Invalid Credentials'),
          of(response))),
        tap((response) => {
          this._tokenManager.setTokens(
            response.token.split(' ')[1],
            '' // FIXME: store refresh token
          );
          this._state.next(this.authenticated);
          return of(response);
        }));
  }

  refreshToken() {
    return this._httpClient.get<string>(ApiConstants.AUTH.refresh)
      .pipe(
        tap((accessToken) => {
          this._tokenManager.setTokens(
            accessToken.split(' ')[1],
            ''
            // response.refreshToken
          );
          this._state.next(this.authenticated);
        })
      );
  }

  refresh(): void {
    this._state.next(this.authenticated);
  }

  logout(redirectUrl = this._router.url): Observable<any> {
    const token = `Bearer ${this._tokenManager.accessToken}`;
    this._tokenManager.deleteTokens();
    this._state.next(this.authenticated);
    this._router.navigate([RoutesConstants.LOGIN.withSlash])
    return this._httpClient.post(ApiConstants.AUTH.logout, {
      token: token
    });
  }

  abstract changePassword(data: AuthModel.ChangePasswordRequest): Observable<any>;
  abstract userRoles: UserRoles[]

}
