export namespace AuthModel {

  export class Login {
    constructor(
      public username: string,
      public password: string,
      public deviceBrand: string,
      public deviceModel: string,
      public deviceToken: string,
      public os: string,
      public osVersion: string,
      public deviceType: string,
      public applicationVersion: string,
    ) {
    }
  }

  export class RefreshToken {
    constructor(
      public accessToken: string,
      public refreshToken: string,
    ) {
    }
  }

  export interface ITokenClaim {
    firstName: string;
    lastName: string;
    isemailverified: boolean;
    sub: string;
    phoneNumber: string;
    role: string;
    id: number;
    exp: number;
    iat: number;
    email: string;
    username: string;
    isenabled: boolean;
  }

  export interface ILoginResponse {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    phoneNumber: string;
    role: string;
    isEnabled: boolean;
    token: string;
    roleGroup: 'INSTRUCTOR' | 'ADMIN';
  }

  export interface ChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }

  export type UserRoles = 'SUPER_ADMIN' | 'ADMIN' | 'CLIENT' | 'INSTRUCTOR' | 'MEMBER'

}
